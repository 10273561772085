import React from 'react'
import { Loader, ErrorSection, Timeline } from 'app/Components'
import { NoteContainer } from 'app/Containers'
import { Container } from 'app/UI'
import { useTranslations } from 'lib/hooks'
import { useNotes } from './useNotes'
import { AvaReplayChallengeReminder } from 'app/Pages/Ava'

export const Notes: React.FunctionComponent = () => {
    const T = useTranslations()
    const {
        isLoading,
        hasError,
        notes,
        canRenderNotes,
        hasEmptyNotes,
        onReload
    } = useNotes()

    return (
        <Container>
            {/* <Announcement /> */}
            {/* <AvaReplayChallengeReminder /> */}
            <Loader isLoading={isLoading} />
            <ErrorSection
                hasError={hasError}
                hasEmptyNotes={hasEmptyNotes}
                onRefetch={onReload}
                message={T.components.notes.fetchError}
            />

            {canRenderNotes && (
                <Timeline>
                    {notes.map(note => (
                        <NoteContainer
                            key={note.appointmentId}
                            note={note}
                        />
                    ))}
                </Timeline>
            )}
        </Container>
    )
}
