import { HttpMethod, OnSuccessResponse } from 'lib/types'
import { useFetch } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import { UserModel } from './types'

export const useGetCurrentUser = (onSuccess: OnSuccessResponse<UserModel>) =>
    useFetch<UserModel>({
        url: `${CONFIG.USER_API}/user/me`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })