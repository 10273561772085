import React, { Fragment, useState } from 'react'
import { Button } from 'app/Components'
import { useGetProductList } from '../actions'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { Product, ProductInfo } from '../types'
import { CONFIG } from 'lib/config'

export interface ProductCardProps {
    product: ProductInfo,
    annualPrice: boolean,
    setProductPurchase: ((product: ProductInfo)=>void)
}

export const ProductCard: React.FunctionComponent<ProductCardProps> = ({
    product,
    annualPrice,
    setProductPurchase
}) => {

    console.log(`PROCESS REACT_APP_STRIPE_PUBLIC_KEY : ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)
    console.log(`PROCESS REACT_APP_SCHEDULER_API : ${process.env.REACT_APP_SCHEDULER_API}`)
    console.log(`PROCESS REACT_APP_VC_API_KEY : ${process.env.REACT_APP_VC_API_KEY}`)
    console.log(`PROCESS REACT_APP_PATH_NAME : ${process.env.REACT_APP_PATH_NAME}`)

    return (
        <ProductView>
            <ProductName>{product.name}</ProductName>
            <ProductDescription>{product.descr}</ProductDescription>
            {annualPrice && (<ProductPrice>{ Math.ceil((product.product.priceYear || 0)/12)} / {product.product.duration}</ProductPrice>)}
            <ProductPrice $isSubPrice={annualPrice}>{product.product.price} / {product.product.duration}</ProductPrice>
            <Button.Button
                height={40}
                fontSize={16}
                backgroundColor={colors.orange}
                textColor={colors.white}
                onClick={() => setProductPurchase(product)}
                content={'Purchase'}
                isLoading={false}
                disabled={false}
            />
        </ProductView>
    )
}

const ProductView = styled.div`
  max-width: 350px;
  min-width: 230px;
  padding: 10px;
  border-radius: 18px;
  box-shadow: '0px 0px 30px #00000029';
  position: relative;
  background-color: ${colors.white};
`

const ProductName = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
  padding: 10px 10px;
`

const ProductDescription = styled.div`
  width: 100%;
  font-size: 16px;
  text-align: left;
  padding: 10px 10px;
  min-height: 370px;
`

const ProductPrice = styled.div<{ $isSubPrice?: boolean }>`
  width: 100%;
  font-size: ${props => props.$isSubPrice ? '16px' : '20px'};
  text-decoration: ${props => props.$isSubPrice ? 'line-through' : 'none'};
  text-align: center;
  padding: 5px 10px;
`