import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NewLoader, Button } from 'app/Components'
import { useGetAssessmentLink } from './actions'
import { EVENT } from './constants'
import { colors } from 'lib/styles'
import { getAuthCookie } from 'lib/api'
import { CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'
import { useGetCurrentUser, UserModel } from 'app/Actions'
import { ADA_PAGE_LINK } from 'lib/utils/constants'

export const Assessment: React.FunctionComponent = () => {
    const [assessmentLink, setAssessmentLink] = useState<string>()
    const [currentUser, setCurrentUser] = useState<UserModel>()
    const [isAssessmentCompleted, setIsAssessmentCompleted] = useState<boolean>(false)
    const [startAssessment, setStartAssessment] = useState<boolean>(false)
    const { fetch: getAssessmentLink, fetchState: { isLoading: gettingAssessmentLink } } = useGetAssessmentLink(setAssessmentLink)
    const userIsLogged = Boolean(getAuthCookie())
    const T = useTranslations()
    const { fetch: getCurrentUser, fetchState: { isLoading: gettingCurrentUser }} = useGetCurrentUser(setCurrentUser)

    useEffect(() => {
        if (userIsLogged) {
            getCurrentUser()
        }
        window.addEventListener(EVENT.MESSAGE, event => {
            // @ts-ignore
            if (event.data.message === EVENT.ASSESSMENT_COMPLETED) {
                setIsAssessmentCompleted(true)
            }
        })
    }, [])

    useEffect(() => {
        if (currentUser && currentUser.contentLanguage !== 'ja-JP') {
            setStartAssessment(true)
        }
    }, [currentUser])

    useEffect(() => {
        if (startAssessment) {
            getAssessmentLink({
                callbackBaseUrl: CONFIG.USER_API
            })
        }
    }, [startAssessment])

    return (
        <Wrapper>
            {!startAssessment && !gettingCurrentUser && (<div>
                {T.pages.assessment.assessmentNote}
                <br/>
                <br/>
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => document.location.href = ADA_PAGE_LINK}
                    content={T.pages.assessment.skipButton}
                    isLoading={false}
                    disabled={false}
                />&nbsp;&nbsp;
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.darkGreen}
                    textColor={colors.white}
                    onClick={() => setStartAssessment(true)}
                    content={T.pages.assessment.takeButton}
                    isLoading={false}
                    disabled={false}
                />
            </div>)}
            {startAssessment && (<div>
                {(gettingAssessmentLink) && (
                    <LoaderWrapper>
                        <NewLoader/>
                    </LoaderWrapper>
                )}
                {!gettingAssessmentLink && !assessmentLink && (
                    <AssessmentNote>
                        There is no pending assessment for you at the moment.
                        <br/>
                        Your LSM will assign one to you when needed.
                    </AssessmentNote>
                )}
                {assessmentLink && !isAssessmentCompleted && (
                    <div id="embedded-hallo-app">
                        <AssessmentContainer
                            allow={'microphone *;fullscreen *;display-capture *'}
                            src={assessmentLink}
                        />
                    </div>
                )}
                {isAssessmentCompleted && currentUser && !currentUser.isAISolo && (
                    <AssessmentNote>
                        {T.pages.assessment.completed}
                        <br/>
                        {T.pages.assessment.schedulePrefix} <a
                            href="/scheduling/index">{T.pages.assessment.scheduleLink}</a> {T.pages.assessment.scheduleSuffix}
                        <br/>
                        2. {T.pages.assessment.talkToAvaPrefix}
                    </AssessmentNote>
                )}
                {isAssessmentCompleted && currentUser && currentUser.isAISolo && (
                    <AssessmentNote>
                        {T.pages.assessment.completedAISolo}
                        <br/>
                        {T.pages.assessment.talkToAvaPrefix}
                    </AssessmentNote>
                )}
            </div>)}
        </Wrapper>
    )

}

const Wrapper = styled.div`
    width: 100%;
    max-width: 920px;
    margin: auto;
    //margin-top: 48px;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
   }
`

const LoaderWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const AssessmentContainer = styled.iframe`
    border: unset;
    width: 100%;
    height: 82vh;
`

const AssessmentNote = styled.div`
    padding-top: 35px;
    padding-left: 10px;
    font-size: 20px;
    width: 100%;
`
