import { ReactEnv } from 'lib/types'

export const CONFIG: ReactEnv = {
    NEI_API:
		window?.env?.REACT_APP_NEI_API || process.env.REACT_APP_NEI_API || '',
    SCHEDULER_API:
        window?.env?.REACT_APP_SCHEDULER_API || process.env.REACT_APP_SCHEDULER_API || '',
    IS_PUBLIC_LANDING_PAGE:
        window?.env?.REACT_APP_IS_PUBLIC_LANDING_PAGE || false,
    CREATE_FREE_ACCOUNT:
        window?.env?.REACT_APP_CREATE_FREE_ACCOUNT || false,
    AVA_LOCATION:
        window?.env?.REACT_APP_AVA_LOCATION || '',
    IS_FREE_CLIENT:
        window?.env?.REACT_APP_IS_FREE_CLIENT || false,
    IS_FREE_PRODUCT:
        window?.env?.REACT_APP_IS_FREE_PRODUCT || false,
    CLIENT_HAS_TAGS:
        window?.env?.REACT_APP_CLIENT_HAS_TAGS || false,
    COMPANY_ID:
        window?.env?.REACT_APP_COMPANY_ID || null,
    REFERRAL_CODE:
        window?.env?.REACT_APP_REFERRAL_CODE || null,
    USER_API:
        window?.env?.REACT_APP_USER_API || process.env.REACT_APP_USER_API || '',
    IMAGE_API:
        window?.env?.REACT_APP_IMAGE_API || process.env.REACT_APP_IMAGE_API || '',
    TIMEOUT:
		window?.env?.REACT_APP_API_TIMEOUT ||
		process.env.REACT_APP_API_TIMEOUT ||
		'',
    PATH_NAME: window?.env?.REACT_APP_PATH_NAME ||
		process.env.REACT_APP_PATH_NAME ||
		'',
    VC_API: window?.env?.REACT_APP_VC_API ||
		process.env.REACT_APP_VC_API ||
		'',
    VC_API_KEY: window?.env?.REACT_APP_VC_API_KEY ||
		process.env.REACT_APP_VC_API_KEY ||
		'',
    COOKIE_KEY: window?.env?.REACT_APP_COOKIE_KEY ||
		process.env.REACT_APP_COOKIE_KEY ||
		'',
    TIMEZONE: window.env?.REACT_APP_TIMEZONE || process.env?.REACT_APP_TIMEZONE,
    LANGUAGE: window.env?.REACT_APP_LANGUAGE || process.env?.REACT_APP_LANGUAGE || 'en_US',
    MODULE: window.env?.REACT_APP_MODULE || process.env?.REACT_APP_MODULE || '1_ON_1S',
    DD_ENV: window?.env?.DD_ENV || 'local',
    DATADOG_CLIENT_TOKEN: window?.env?.REACT_APP_DATADOG_CLIENT_TOKEN || process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    DATADOG_APP_ID: window?.env?.REACT_APP_DATADOG_APP_ID || process.env.REACT_APP_DATADOG_APP_ID || ''
}
