import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NewLoader } from 'app/Components'
import { useGetAssessmentLink } from './actions'
import { EVENT } from './constants'
import { colors } from 'lib/styles'
import { getAuthCookie } from 'lib/api'
import { CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'

export const Assessment: React.FunctionComponent = () => {
    const [assessmentLink, setAssessmentLink] = useState<string>()
    const [isAssessmentCompleted, setIsAssessmentCompleted] = useState<boolean>(false)
    const { fetch: getAssessmentLink, fetchState: { isLoading: gettingAssessmentLink } } = useGetAssessmentLink(setAssessmentLink)
    const userIsLogged = Boolean(getAuthCookie())
    const T = useTranslations()

    useEffect(() => {
        if (userIsLogged) {
            getAssessmentLink({
                callbackBaseUrl: CONFIG.USER_API
            })
        }
        window.addEventListener(EVENT.MESSAGE, event => {
            // @ts-ignore
            if (event.data.message === EVENT.ASSESSMENT_COMPLETED) {
                setIsAssessmentCompleted(true)
            }
        })
    }, [])

    return (
        <Wrapper>
            {(gettingAssessmentLink) && (
                <LoaderWrapper>
                    <NewLoader/>
                </LoaderWrapper>
            )}
            {!gettingAssessmentLink && !assessmentLink && (
                <AssessmentNote>
                    There is no pending assessments for you at the moment.
                    <br/>
                    You LSM will assign one to you when needed.
                </AssessmentNote>
            )}
            {assessmentLink && !isAssessmentCompleted && (
                <div id="embedded-hallo-app">
                    <AssessmentContainer
                        allow={'microphone *;fullscreen *;display-capture *'}
                        src={assessmentLink}
                    />
                </div>
            )}
            {isAssessmentCompleted && (
                <AssessmentNote>
                    {T.pages.assessment.completed}
                    <br/>
                    {T.pages.assessment.talkToAvaPrefix}<a href="/client/ada">{T.pages.assessment.talkToAvaLink}</a>{T.pages.assessment.talkToAvaSuffix}
                </AssessmentNote>
            )}
        </Wrapper>
    )

}

const Wrapper = styled.div`
    width: 100%;
    max-width: 920px;
    margin: auto;
    //margin-top: 48px;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
   }
`

const LoaderWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const AssessmentContainer = styled.iframe`
    border: unset;
    width: 100%;
    height: 82vh;
`

const AssessmentNote = styled.div`
    padding-top: 35px;
    padding-left: 10px;
    font-size: 20px;
    width: 100%;
`
