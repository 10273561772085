/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useGetProductList, useGetStripeKey } from '../actions'
import { ProductInfo, ProductListResponse } from '../types'
import { Button } from 'app/Components'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { ProductCard } from './ProductCard'
import { ProductPurchase } from './ProductPurchase'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'

export const ProductList: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isAnnualPrice, setIsAnnualPrice] = useState<boolean>(true)
    const [purchaseProduct, setPurchaseProduct] = useState<ProductInfo>()
    const [productList, setProductList] = useState<Array<ProductInfo>>([])
    const [stripePublicKey, setStripePublicKey] = useState<string>('')
    const [registrationReferral, setRegistrationReferral] = useState<string>()
    const {fetch: getStripePK} = useGetStripeKey(setStripePublicKey)
    const {fetch: getProductList} = useGetProductList(response => {
        const products = response.productInfo
        setRegistrationReferral(response.registrationReferral)
        const productsInfo = new Array<ProductInfo>()
        products.forEach(product => {
            if (product.price === 0) {
                productsInfo.push({
                    product_id: product.id,
                    productYearId: product.id,
                    name: 'Try Free',
                    descr: '',
                    product: {
                        duration: product.duration,
                        is_ai_solo: product.isAiSolo,
                        is_ava_available: product.isAdaEnabled,
                        is_micro_topic_available: product.isMicroTopicsEnabled,
                        price: 0,
                        priceYear: 0,
                        recurring_payments_disabled: false
                    }
                })
            } else {
                const productName = product.isAiSolo ? 'AI Solo' : (product.isMicroTopicsEnabled ? 'Hybrid Pro' : 'Hybrid')
                if (product.duration === '1 month') {
                    productsInfo.push({
                        product_id: product.id,
                        name: productName,
                        descr: '',
                        product: {
                            duration: product.duration,
                            is_ai_solo: product.isAiSolo,
                            is_ava_available: product.isAdaEnabled,
                            is_micro_topic_available: product.isMicroTopicsEnabled,
                            price: product.price,
                            recurring_payments_disabled: false
                        }
                    })
                } else {
                    const index = productsInfo.findIndex(product => product.name === productName)
                    productsInfo[index].product.priceYear = product.price
                    productsInfo[index].productYearId = product.id
                    productsInfo[index].product.recurring_payments_disabled = product.recurringPaymentsDisabled
                }
            }
        })
        setProductList(productsInfo)
        console.log(`[product info] full list ${productsInfo}`)
    })

    useEffect(() => {
        getStripePK()
        getProductList()
    }, [])

    return (
        <Container>
            {!purchaseProduct && (<div>
                <LinksContainer>
                    <Link $isActive={!isAnnualPrice} onClick={() => setIsAnnualPrice(false)}>{T.pages.purchase.monthly}</Link>
                    <Link $isActive={isAnnualPrice} onClick={() => setIsAnnualPrice(true)}>{T.pages.purchase.annual}</Link>
                </LinksContainer>
                <PricingTable>
                    <thead>
                        <tr>
                            <th> </th>
                            {productList.map((info, index) => {
                                return (<PricingProductName key={index}>{info.name}</PricingProductName>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <PricingFeature>{T.pages.purchase.assessmentFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{(info.product.price || 0) > 0 ? T.common.yes : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.lessonsFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{info.name.indexOf('Solo') === -1 && (info.product.price || 0) > 0 ? (isAnnualPrice && info.productYearId ? 48 : 4) : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.lsmFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{info.name.indexOf('Solo') === -1 && (info.product.price || 0) > 0 ? T.common.yes : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.assignmentsFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{info.name.indexOf('Solo') === -1 && (info.product.price || 0) > 0 ? T.common.yes : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.adaFlexFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{(info.product.price || 0) > 0 ? T.common.yes : T.pages.purchase.preview}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.adaMTFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{ info.product.is_micro_topic_available ? ((info.product.price || 0) > 0 ? T.common.yes : T.pages.purchase.preview) : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.adaConversationHistoryFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{ info.product.is_micro_topic_available ? ((info.product.price || 0) > 0 ? T.common.yes : T.pages.purchase.preview) : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                        <tr>
                            <PricingFeature>{T.pages.purchase.adaVocabularySuggestionsFeature}</PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>{ info.product.is_micro_topic_available ? ((info.product.price || 0) > 0 ? T.common.yes : T.pages.purchase.preview) : '-'}</PricingFeatureValue>)
                            })}
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <PricingFeature> </PricingFeature>
                            {productList.map((info, index) => {
                                return (<PricingFeatureValue key={index}>
                                    {(!isAnnualPrice || info.productYearId) && (<span>
                                        {isAnnualPrice && ((info.product.price || 0) > 0) && (<ProductPrice>${ Math.ceil((info.product.priceYear || 0)/12)} / {T.pages.purchase.month}</ProductPrice>)}
                                        {((info.product.price || 0) > 0) && (<ProductPrice
                                            $isSubPrice={isAnnualPrice}>${info.product.price} / {T.pages.purchase.month}</ProductPrice>)}
                                        {((info.product.price || 0) === 0) && (<ProductPrice>{T.pages.purchase.free}</ProductPrice>)}
                                        <br/>
                                        <Button.Button
                                            height={40}
                                            fontSize={16}
                                            backgroundColor={(info.product.price || 0 > 0) ? colors.orange : colors.darkGreen}
                                            textColor={colors.white}
                                            onClick={() => CONFIG.IS_PUBLIC_LANDING_PAGE ? window.location.href = '/site/try-free?skipAbout=1'
                                                : ((info.product.price || 0 > 0) ? setPurchaseProduct(info) : window.location.href = '/client/try-free')}
                                            content={(info.product.price || 0 > 0) ? T.pages.purchase.purchase : T.pages.purchase.getFree}
                                            isLoading={false}
                                            disabled={false}
                                        />
                                    </span>)}
                                    {(isAnnualPrice && !info.productYearId) && (<InlineLink onClick={() => setIsAnnualPrice(false)}><b>{T.pages.purchase.monthlyOnly}</b></InlineLink>)}
                                </PricingFeatureValue>)
                            })}
                        </tr>
                    </tfoot>
                </PricingTable>
                {/* <ProductCardList>*/}
                {/*    {productList.map((info, index) => {*/}
                {/*        return info && info.product ? (*/}
                {/*            <ProductCard product={info} annualPrice={isAnnualPrice} key={index}*/}
                {/*                setProductPurchase={setPurchaseProduct}/>) : ('')*/}
                {/*    })}*/}
                {/* </ProductCardList>*/}
            </div>)}
            {purchaseProduct && (<ProductPurchase
                product={purchaseProduct}
                annualPriceProp={isAnnualPrice}
                stripePublicKey={stripePublicKey}
                registrationReferral={registrationReferral}
                setProductPurchase={setPurchaseProduct} />)}
        </Container>
    )
}

const Container = styled.div`
    padding: 10px 15px 10px 0;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

const LinksContainer = styled.div`
    display: flex;
  margin-bottom: 15px;
  justify-content: center;
`

const Link = styled.a<{ $isActive: boolean }>`
  text-wrap: nowrap;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.gray.border};
  background-color: ${props => props.$isActive ? colors.orange : 'none'};
  color: ${props => props.$isActive ? colors.white : colors.typography.blue};
  
  &:hover {
    background-color: ${colors.orange};
    color: ${colors.typography.blue};
    text-decoration: none;
  }
`

const InlineLink = styled.a`
  cursor: pointer;
`

const ProductCardList = styled.div`
    padding: 10px 15px 10px 0;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
`

const ProductPrice = styled.div<{ $isSubPrice?: boolean }>`
  width: 100%;
  font-size: ${props => props.$isSubPrice ? '16px' : '20px'};
  text-decoration: ${props => props.$isSubPrice ? 'line-through' : 'none'};
  text-align: center;
  white-space: nowrap;
  padding: 5px 10px;
`

const PricingTable = styled.table`
  tbody tr:nth-child(odd) {
    background-color: ${colors.gray.light};
  }
  
  tfoot td {
    vertical-align: top;
  }
`

const PricingFeature = styled.td`
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  width: 30%;
  font-weight: bold;
`

const PricingProductName = styled.th`
  padding: 20px 10px;
  text-align: center;
  vertical-align: middle;
  width: 17%;
`

const PricingFeatureValue = styled.td`
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  width: 17%;
`

const BonusNote = styled.div`
  font-weight: bold;
  color: ${colors.success};
  margin-top: 10px;
`