import React from 'react'
import styled from 'styled-components'
import { dateHelpers } from 'lib/utils'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { FilterType, HeaderAlignment, KeyValuePair, TableConfig } from 'lib/types'
import { Filter } from 'app/Components'
import { AvaConversation, AvaConversationMicroTopic } from './types'
import { fromUnixTime } from 'date-fns'

export const ENABLE_MT_COOKIE_KEY = 'temporary-ada-mt-access'
export const DEFAULT_AVA_SETTINGS = {
    handsFree: false,
    autoSubmitTimeout: 3,
    speechSpeed: 'slow',
    enableCorrections: true
}

export const AVA_PUBLIC_LIMIT = 4
export const AVA_DAILY_LIMIT = 7
export const AVA_MT_LIMIT = 3
export const AVA_DETAILS_LIMIT = 3

export const CONVERSATIONS_LIST_URL = '/client/ada-conversations'
export const ADA_HOME_URL = '/client/ada'

export const WORD_PRACTICE_ORIGIN_PARAM = 'originId'
export const AVA_VIEW_MODE_PARAM = 'avaLocation'

export const AVA_CONVERSATION_LIST = {
    ALL: 'all',
    MICRO_TOPIC: 'microTopic'
}

export const AVA_VIEW_MODE = {
    HOME: '',
    FLEX: 'flex',
    MICRO_TOPIC: 'microTopic',
    WORD_PRACTICE: 'wordPractice'
}

export const NO_LIMIT_REFERRALS = [
    'nonExistentProCode'
]

export const GPT_ROLE = {
    SYSTEM: 'system',
    USER: 'user',
    ASSISTANT: 'assistant'
}

export const CONVERSATION_TYPE = {
    FLEX: 'flex',
    REPLAY_CHALLENGE: 'replay challenge',
    MICRO_TOPIC: 'micro topic',
    WORD_PRACTICE: 'word practice'
}

export const AVA_SPEECH_SPEED = [
    { value: 'x-slow', label: 'Very slow' },
    { value: 'slow', label: 'Slow' },
    { value: 'medium', label: 'Normal' }
]

interface IDictionary<T> {
    [index: string]: T
}

export const MICRO_TOPIC_TITLES = {} as IDictionary<string>
MICRO_TOPIC_TITLES.qaBasicsQs = 'QA Software testing basics'
MICRO_TOPIC_TITLES.facilitatingMeetings = 'Facilitating meetings'
MICRO_TOPIC_TITLES.gameDevMidEngineer = 'Game Development Engineering'
MICRO_TOPIC_TITLES.revenueModelStartups = 'Startups: Revenue Model'
MICRO_TOPIC_TITLES.marketOpportunityScalingStartups = 'Startups: Market Opportunity & Scaling'
MICRO_TOPIC_TITLES.teamStartups = 'Startups: Team'
MICRO_TOPIC_TITLES.gotoMarketStartups = 'Startups: Go-to-Market'

export const LANGUAGE_CODE = {
    japanese: 'ja-JP',
    english: 'en-US'
}
export const DEFAULT_LANGUAGE = LANGUAGE_CODE.english
export const LANGUAGE_PROMPTS = {} as IDictionary<KeyValuePair>
LANGUAGE_PROMPTS[LANGUAGE_CODE.english] = {
    replyChallengeGreeting: 'Please tell me your Replay Challenge answer. Click the red button to start talking.',
    replyChallengeHandsFreeGreeting: 'Please tell me your Replay Challenge answer.',
    publicGreeting: [
        'Tell me about yourself. What’s a personal project or goal you’re currently working on?',
        'Tell me about yourself. What’s an event or plan you’re really looking forward to?',
        'Tell me why English is important to you. If you woke up tomorrow and magically spoke perfect English, what would you do?',
        'How is your week going? What’s the best thing that’s happened to you this week?',
        'Let’s get to know each other. I’m hungry. Should I eat chocolate or sushi?'
    ],
    hello: 'Hello!',
    clickMicrophone: 'Click the red button to start talking.'
}
LANGUAGE_PROMPTS[LANGUAGE_CODE.japanese] = {
    replyChallengeGreeting: 'リプレイ・チャレンジの答えを教えてください。マイクをクリックして話し始めてください。',
    replyChallengeHandsFreeGreeting: 'リプレイ・チャレンジの答えを教えてください。',
    publicGreeting: ['今日はどんな話をしたいですか？あなたの仕事や好きな趣味は何ですか？'],
    hello: 'こんにちは！',
    clickMicrophone: 'マイクをクリックすると話し始めます。'
}

export const useAvaConversationsFilterTableConfig = (): Array<Filter> => {
    const T = useTranslations()

    return (
        [
            {
                key: 'startDate',
                typ: FilterType.DatePicker,
                width: 200,
                label: T.pages.avaConversations.startDate
            },
            {
                key: 'endDate',
                typ: FilterType.DatePicker,
                width: 200,
                label: T.pages.avaConversations.dateTo
            }
        ]
    )
}

type AvaConversationsTableConfigProps = {
    rolePlay?: string,
    setAvaConversationUid(uid: string): void
}

export const useAvaConversationsTableConfig = ({setAvaConversationUid, rolePlay}: AvaConversationsTableConfigProps): Array<TableConfig<AvaConversation>> => {
    const T = useTranslations()
    const result = []
    result.push()

    return rolePlay ? [
        {
            label: '#',
            key: 'lp',
            width: 30,
            alignment: HeaderAlignment.Center,
            withoutSorting: true,
            orderNumbers: true
        },
        {
            label: T.common.date,
            key: 'created_at',
            width: 100,
            renderCustomCell: row => (dateHelpers.formatToLocaleDateString(fromUnixTime(row.created_at)))
        },
        {
            label: T.pages.avaConversations.turns,
            key: 'turns',
            width: 40
        },
        {
            label: T.common.completed,
            key: 'status',
            width: 80,
            renderCustomCell: row => (row.turns && row.turns >= 7 ? T.common.yes : T.common.no)
        },
        {
            label: T.pages.avaConversations.details,
            key: 'details',
            withoutSorting: true,
            width: 80,
            renderCustomCell: row => row.summary ? (
                <Link onClick={() => setAvaConversationUid(row.uid)}>
                    {T.pages.avaConversations.details}
                </Link>
            ) : (<b>Processing...</b>)
        }
    ] : [
        {
            label: '#',
            key: 'lp',
            width: 30,
            alignment: HeaderAlignment.Center,
            withoutSorting: true,
            orderNumbers: true
        },
        {
            label: T.common.date,
            key: 'created_at',
            width: 100,
            renderCustomCell: row => (dateHelpers.formatToLocaleDateString(fromUnixTime(row.created_at)))
        },
        {
            label: T.pages.avaConversations.turns,
            key: 'turns',
            width: 30
        },
        {
            label: T.pages.avaConversations.type,
            key: 'type',
            width: 80
        },
        {
            label: T.pages.avaConversations.topic,
            key: 'role_play',
            width: 150,
            renderCustomCell: row => row.role_play?.replace(/([a-z\d])([A-Z])/g, '$1 $2')
        },
        {
            label: T.pages.avaConversations.details,
            key: 'details',
            withoutSorting: true,
            width: 60,
            renderCustomCell: row => row.summary ? (
                <Link onClick={() => setAvaConversationUid(row.uid)}>
                    {T.pages.avaConversations.details}
                </Link>
            ) : (<b>Processing...</b>)
        }
    ]
}

type AvaMicroTopicConversationsTableConfigProps = {
    setAvaMicroTopic(microTopic: string): void
}

export const useAvaMicroTopicConversationsTableConfig = ({setAvaMicroTopic}: AvaMicroTopicConversationsTableConfigProps): Array<TableConfig<AvaConversationMicroTopic>> => {
    const T = useTranslations()

    return [
        {
            label: '#',
            key: 'lp',
            width: 30,
            alignment: HeaderAlignment.Center,
            withoutSorting: true,
            orderNumbers: true
        },
        {
            label: T.pages.avaConversations.topic,
            key: 'role_play',
            width: 150,
            renderCustomCell: row => row.role_play?.replace(/([a-z\d])([A-Z])/g, '$1 $2')
        },
        {
            label: T.common.date,
            key: 'last_conversation',
            width: 100,
            renderCustomCell: row => (row.last_conversation ? dateHelpers.formatToLocaleDateString(fromUnixTime(row.last_conversation)) : '')
        },
        {
            label: T.pages.avaConversations.amount,
            key: 'conversations_amount',
            width: 80
        },
        {
            label: T.pages.avaConversations.details,
            key: 'details',
            withoutSorting: true,
            width: 60,
            renderCustomCell: row => (
                <Link onClick={() => setAvaMicroTopic(row.role_play || '')}>
                    {T.pages.avaConversations.details}
                </Link>
            )
        }
    ]
}

const Link = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    text-decoration: unset;
    cursor: pointer;
    color: ${colors.typography};
`
