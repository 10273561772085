import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { colors } from 'lib/styles'
import { useGetAvaMicroTopics, useGetAvaUserStats, useGetAvaWordPracticeTopics } from './actions'
import { Button } from 'app/Components'
import { useGetCurrentUser, UserModel } from 'app/Actions'
import { AvaSession } from './screens/AvaSession'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import { AvaMicroTopicGroup, GetAvaUserStats } from './types'
import { AvaRolePlays } from './AvaRolePlays'
import { Nullable } from 'lib/types'
import {
    AVA_CONVERSATION_LIST,
    AVA_MT_LIMIT, AVA_VIEW_MODE,
    CONVERSATION_TYPE,
    ENABLE_MT_COOKIE_KEY,
    NO_LIMIT_REFERRALS
} from './constants'
import { PURCHASE_PRODUCT_LINK } from 'lib/utils/constants'
import { getCookieByKey } from 'lib/api'
import { AvaWordPractice } from './screens/AvaWordPractice'

export const Ava: React.FunctionComponent = () => {
    const T = useTranslations()
    const [hasConsent, setHasConsent] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<UserModel>()
    const [microTopics, setMicroTopics] = useState<Array<AvaMicroTopicGroup>>()
    const [wordPracticeTopics, setWordPracticeTopics] = useState<Array<AvaMicroTopicGroup>>()
    const [expandedMicroTopicGroups, setExpandedMicroTopicGroups] = useState<Array<number>>([])
    const [isMicroTopicsLoaded, setIsMicroTopicsLoaded] = useState<boolean>(false)
    const [hasMicroTopics, setHasMicroTopics] = useState<boolean>(false)
    const [hasWordPracticeTopics, setHasWordPracticeTopics] = useState<boolean>(false)
    // const [showAdaFlex, setShowAdaFlex] = useState<boolean>(CONFIG.AVA_LOCATION === 'flex')
    // const [showMicroTopics, setShowMicroTopics] = useState<boolean>(CONFIG.AVA_LOCATION === 'microTopics')
    // const [showWordPracticeTopics, setShowWordPracticeTopics] = useState<boolean>(CONFIG.AVA_LOCATION === 'wordPractice')
    const [viewMode, setViewMode] = useState<string>(CONFIG.AVA_LOCATION)
    const [rolePlay, setRolePlay] = useState<Nullable<string>>()
    const [wordPractice, setWordPractice] = useState<Nullable<string>>()
    const [rolePlayTitle, setRolePlayTitle] = useState<Nullable<string>>()
    const [currentUserStats, setCurrentUserStats] = useState<GetAvaUserStats>()
    const { fetch: getAvaMicroTopics } = useGetAvaMicroTopics(response => {
        setIsMicroTopicsLoaded(true)
        if (response.groups) {
            setMicroTopics(response.groups)
            setHasMicroTopics(true)
        }
    })
    const { fetch: getAvaWordPracticeTopics } = useGetAvaWordPracticeTopics(response => {
        // setIsMicroTopicsLoaded(true)
        if (response.groups) {
            setWordPracticeTopics(response.groups)
            setHasWordPracticeTopics(true)
        }
    })

    const { fetch: getAvaStats } = useGetAvaUserStats(setCurrentUserStats)
    const { fetch: getCurrentUser} = useGetCurrentUser(setCurrentUser)

    const findInGroup = (group: AvaMicroTopicGroup, topic: string): Array<number>|null => {
        if (group.topics) {
            const topicIndex = group.topics.findIndex(item => item.alias === topic)
            if (topicIndex !== -1) {
                return []
            }
        }
        if (group.groups) {
            for (let i = 0; i < group.groups.length; i++) {
                const topicInGroup = findInGroup(group.groups[i], topic)
                if (topicInGroup) {
                    topicInGroup.push(i)

                    return topicInGroup
                }
            }
        }

        return null
    }

    useEffect(() => {
        if (!microTopics || !rolePlay) {
            return
        }
        for (let i = 0; i < microTopics.length; i++) {
            const topicInGroup = findInGroup(microTopics[i], rolePlay)
            if (topicInGroup) {
                topicInGroup.push(i)

                setExpandedMicroTopicGroups(topicInGroup.reverse())
            }
        }
    }, [rolePlay])

    useEffect(() => {
        const enableMT = Boolean(getCookieByKey(ENABLE_MT_COOKIE_KEY))
        getCurrentUser()
        getAvaStats()
        getAvaMicroTopics({enableMT})
        getAvaWordPracticeTopics()
    }, [])

    useEffect(() => {
        if (!currentUser || !currentUserStats) {
            return
        }
        if (currentUser.referral && NO_LIMIT_REFERRALS.indexOf(currentUser.referral.toLowerCase()) !== -1) {
            if (currentUserStats.totalMTConversations === 0) {
                setViewMode(AVA_VIEW_MODE.MICRO_TOPIC)
            }

            return
        }
        if (CONFIG.IS_FREE_PRODUCT && viewMode === AVA_VIEW_MODE.HOME) {
            if (currentUserStats.totalConversations === 0) {
                setViewMode(AVA_VIEW_MODE.FLEX)
            } else {
                if (currentUserStats.totalMTConversations === 0) {
                    setViewMode(AVA_VIEW_MODE.MICRO_TOPIC)
                }
            }
        }
    }, [currentUserStats, currentUser])

    const isGroupExpanded = (index: number) => {
        const microTopicGroups = microTopics?.length || 0

        return microTopicGroups <= 2 || (expandedMicroTopicGroups.length > 0 && expandedMicroTopicGroups[0] === index)
    }

    const microTopicsAvailable = () => {
        const referral = currentUser?.referral || ''

        return !CONFIG.IS_FREE_CLIENT
            || (CONFIG.IS_FREE_CLIENT
                && ((currentUserStats?.totalMTConversations || 0) <= AVA_MT_LIMIT))
            || NO_LIMIT_REFERRALS.indexOf(referral.toLowerCase()) !== -1
    }

    return (
        <Wrapper>
            {hasMicroTopics && viewMode !== AVA_VIEW_MODE.HOME && (<SubNavigationContainer>
                <SubNavigationLink $isActive={viewMode === AVA_VIEW_MODE.FLEX} onClick={() => setViewMode(AVA_VIEW_MODE.FLEX)}>Flex Ada™</SubNavigationLink>
                <SubNavigationLink $isActive={viewMode === AVA_VIEW_MODE.MICRO_TOPIC} onClick={() => setViewMode(AVA_VIEW_MODE.MICRO_TOPIC)}>Micro-Topics Ada™</SubNavigationLink>
                {currentUser && currentUser.wordPracticeEnabled && (
                    <SubNavigationLink $isActive={viewMode === AVA_VIEW_MODE.WORD_PRACTICE}
                        onClick={() => setViewMode(AVA_VIEW_MODE.WORD_PRACTICE)}>Vocabtivate™</SubNavigationLink>)}
            </SubNavigationContainer>)}
            {isMicroTopicsLoaded && (!hasMicroTopics || viewMode === AVA_VIEW_MODE.FLEX) && (<AvaSession type={CONVERSATION_TYPE.FLEX}/>)}
            {isMicroTopicsLoaded && (hasMicroTopics || hasWordPracticeTopics) && viewMode === AVA_VIEW_MODE.HOME && (<div>
                {hasMicroTopics && (<Section>
                    <Link onClick={() => setViewMode(AVA_VIEW_MODE.MICRO_TOPIC)}>Micro-Topics Ada™</Link>
                    <Note $isAlert $isEnabled={((currentUser?.recentAdaMT || 0) === 0) && !CONFIG.IS_FREE_CLIENT || false}>
                        <AlertIcon>!</AlertIcon>
                        {T.pages.avaSession.microTopicsPending}
                    </Note>
                    <Note $isEnabled={currentUser?.microTopicsEnabled || false}>{T.pages.avaSession.microTopicsLimitedAccess}</Note>
                    {T.pages.avaSession.microTopicsDescription}
                </Section>)}
                <Section>
                    <Link onClick={() => setViewMode(AVA_VIEW_MODE.FLEX)}>Flex Ada™</Link>
                    {T.pages.avaSession.flexDescription}
                </Section>
                {currentUser && currentUser.wordPracticeEnabled && (<Section>
                    <Link onClick={() => setViewMode(AVA_VIEW_MODE.WORD_PRACTICE)}>Vocabtivate™</Link>
                    Vocabtivate™ is the name of Ada’s new feature that <i>activates</i> your vocabulary suggestions. It’s perfect for when you’re commuting. With headphones, you hear Ada™, but you don’t need to speak. Ada™ presents three sentences using your vocabulary word and you choose the one that’s best.
                </Section>)}
            </div>)}
            {!rolePlay && microTopics && viewMode === AVA_VIEW_MODE.MICRO_TOPIC && (<div>
                {!microTopicsAvailable() && (<div>
                    <Text>{T.pages.avaSession.microTopicsLimit}</Text>
                    <br/>
                    <Button.Button
                        height={40}
                        fontSize={16}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={() => document.location.href = PURCHASE_PRODUCT_LINK}
                        content={T.common.purchaseSubscription}
                        isLoading={false}
                        disabled={false}
                    />
                </div>)}
                {microTopicsAvailable() && (<Text>{T.pages.avaSession.microTopicsInstruction}</Text>)}
                {microTopicsAvailable() && microTopics.map((group, index) => {
                    return (
                        <AvaRolePlays
                            expandList={isGroupExpanded(index)}
                            expandedGroupsHierarchy={isGroupExpanded(index) ? expandedMicroTopicGroups.slice(1) : []}
                            group={group}
                            outerSetRolePlay={setRolePlay}
                            outerSetRolePlayTitle={setRolePlayTitle}
                            key={index} />
                    )
                })}
            </div>)}
            {rolePlay && (<div>
                <RolePlayTitle>{rolePlayTitle}</RolePlayTitle>
                <BackLink onClick={() => setRolePlay(null)}>&lt; Back</BackLink>
                <AvaSession type={CONVERSATION_TYPE.MICRO_TOPIC} topic={rolePlay}/>
            </div>)}
            {viewMode === AVA_VIEW_MODE.WORD_PRACTICE && (<div>
                <AvaWordPractice type={CONVERSATION_TYPE.WORD_PRACTICE} />
            </div>)}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 620px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const Text = styled.div`
    margin: 20px 0 15px 0;
    font-size: 18px;
    line-height: 22px;
`

const Link = styled.div`
    max-width: 500px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
    padding: 10px 0;
    font-weight: bold;
    color: ${colors.linkColor};
`

const Section = styled.div`
    max-width: 600px;
    font-size: 18px;
    padding: 10px 0;
    line-height: 22px;
`

const RolePlayTitle = styled.div`
    padding: 15px 0;
    font-size: 24px;
`

const BackLink = styled.div`
    width: 300px;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 10px 15px 0;
`

const Note = styled.div<{ $isEnabled: boolean; $isAlert?: boolean }>`
  color: ${props => props.$isAlert ? colors.orange : 'inherit'};
  padding-bottom: 10px;
  font-weight: bold;
  display: ${props => props.$isEnabled ? 'block' : 'none'};
`

const AlertIcon = styled.span`
  color: ${colors.white}; 
  padding: 0.01em 0.5em; 
  background-color: ${colors.orange}; 
  border-radius: 100%; 
  width: auto; 
  display: inline-block;
  margin-right: 7px;
`

const SubNavigationLink = styled.a<{ $isActive: boolean }>`
  text-wrap: nowrap;
  margin-right: 25px;
  cursor: pointer;
  text-decoration: ${props => props.$isActive ? 'none' : 'underline'};
  padding: 10px;
  border-radius: 4px;
  background-color: ${props => props.$isActive ? colors.gray.background : 'none'};
  
  &:hover {
    background-color: ${colors.gray.background};
    text-decoration: none;
  }
`

const SubNavigationContainer = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
`