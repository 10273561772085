import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { SignUpFields } from './types'

export const useSignUpForm = (initialReferral: string = '') => {
    const T = useTranslations()

    const firstName = useField({
        key: SignUpFields.FirstName,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: 'John',
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })
    const lastName = useField({
        key: SignUpFields.LastName,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: 'Smith',
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })
    const email = useField({
        key: SignUpFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.pages.signUp.emailPlaceholder,
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            },
            {
                errorMessage: T.validationErrors.email,
                validate: regexes.isValidEmail
            }
            // ,
            // {
            //     errorMessage: T.validationErrors.emailICloud,
            //     validate: email => email.toLocaleLowerCase().indexOf('@icloud.com') === -1
            // }
        ]
    })
    const referralCode = useField({
        key: SignUpFields.ReferralCode,
        initialValue: initialReferral,
        isRequired: false,
        validateOnBlur: true,
        placeholder: 'PromoCode'
    })

    const newPassword = useField<string>({
        initialValue: '',
        key: SignUpFields.NewPassword,
        placeholder: T.pages.signUp.createPassword,
        validateOnBlur: true,
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.validationErrors.password,
                validate: (value: string) => regexes.isValidPassword(value)
            }
        ]
    })
    const confirmNewPassword = useField<string>({
        initialValue: '',
        key: SignUpFields.ConfirmNewPassword,
        placeholder: T.pages.signUp.confirmPassword,
        validateOnBlur: true,
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.validationErrors.dontMatchPassword,
                validate: (confirmedPassword: string) => confirmedPassword === newPassword.value
            },
            {
                errorMessage: T.validationErrors.password,
                validate: (value: string) => regexes.isValidPassword(value)
            }
        ]
    })
    const acceptTerms = useField<boolean>({
        initialValue: false,
        key: SignUpFields.AcceptTerms,
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.validationErrors.agreeTerms,
                validate: Boolean
            }
        ]
    })

    return {
        firstName,
        lastName,
        email,
        confirmNewPassword,
        newPassword,
        acceptTerms,
        referralCode
    }
}
