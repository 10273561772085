import { FormComponents } from 'app/Components'

export enum AvaSettingsFields {
    IsHandsFree = 'isHandsFree',
    AutoSubmitTimeout = 'autoSubmitTimeout',
    SpeechSpeed = 'speechSpeed',
    EnableCorrections = 'enableCorrections',
}

export type AvaSettingsFormShape = {
    [AvaSettingsFields.IsHandsFree]: boolean,
    [AvaSettingsFields.EnableCorrections]: boolean,
    [AvaSettingsFields.AutoSubmitTimeout]: number,
    [AvaSettingsFields.SpeechSpeed]: FormComponents.SelectOption
}

export enum AddWordPracticeFields {
    WordText = 'wordText'
}

export type AddWordPracticeFormShape = {
    [AddWordPracticeFields.WordText]: string
}