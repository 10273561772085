import { Dictionary } from 'lib/types'

export const ja_JP: Dictionary = {
    common: {
        tryAgain: 'もう一度お試しください。',
        inputPlaceholder: 'Input text',
        noResults: '結果が見つかりません',
        search: '検索...',
        close: '閉じる',
        save: '保存',
        seconds: '秒',
        doNotShow: '次回表示しない',
        newReleaseAnnouncement: 'Hi from your personalized AI Aide!',
        purchaseSubscription: 'サブスクリプションを購入する',
        retry: 'Retry',
        noMatchResult: 'No result found',
        yes: 'Yes',
        no: 'No',
        date: 'Date',
        error: {
            fetchError: 'There was an error during fetching data, please try again'
        },
        seeInAction: 'ご覧ください!',
        completed: 'Completed'
    },
    announcements: {
        AvaBetaRelease2023: 'Hi from your personalized AI Aide!',
        replyChallengeReminder: {
            initial: {
                title: 'Ada™とはまだ話していないようですね！',
                note: '数分でも良いのでリプレイ・チャレンジの答えをAda™にお話しください。これは各レッスンにおける必須項目でもあり、目標スコアを達成するための重要な練習です！'
            },
            longMissing: {
                title: 'Ada™と習慣的に話しましょう！',
                note: 'Ada™と最後に話してからしばらく経っています！定期的に話す練習をすることが大切です。Replay Challenge の答えをAda™に伝えることは、NEIメソッドの必須項目の一つです。'
            }
        }
    },
    pages: {
        notFound: '見つかりません。',
        timeLabel: '時間',
        RecordingsTitle: '録画',
        noteRemeber: '最後まで忘れずにお聞きください。',
        uploadButton: 'アップロード',
        recordNote: 'Replay Challenge™の録音はこちらからできます。',
        recordButton: '録音',
        noRecordsAvailable: '録画はまだ存在しません',
        avaConsent: {
            introduction: 'Ada Alpha Testに参加することで、私はこれらの条件に同意します：',
            underConstruction: '1. 私は、このソフトウェアサービスがまだ構築中であることを理解しています。本ソフトウェアサービスはまだ構築中であり、バグがないか徹底的なテストは行われていません。NEIは、サービスの稼働時間やパフォーマンスについて保証しないものとします。NEI は、私のフィードバックと引き換えに、早期アクセスを無償で提供します。私は、Adaを利用した経験について、NEIに対していかなる請求も行わず、責任も問いません。',
            intellectualProperty: '2. 私はNEIの知的財産権を尊重することに同意します。私は、リバースエンジニアリング、逆コンパイル、逆アセンブル、またはその他の方法でNEIのソフトウェアのソースコードを導出する試みを行いません。私は、NEI のソフトウェアをコピー、複製、配布、または二次的著作物を作成しません。',
            participation: '3. 私は、このテストに毎週参加しなかった場合、アルファテストから削除される可能性があることを理解します。',
            agree: '同意します'
        },
        avaConversations : {
            listTitle: 'Ada™ Conversations',
            singleTitle: 'Ada™ Conversation',
            summary: 'Summary',
            status: 'Status',
            detailsLimit: 'You have reached the limit of Ada™ conversations summary review for Try Free account. Purchase a subscription to have unlimited access to Ada™ conversations summary, transcripts, and vocabulary suggestions.',
            recommendations: 'Suggestions',
            start: 'Created',
            lastMessage: 'Last message',
            turns: 'Turns',
            studentOutput: 'Your output (words)',
            teacherOutput: 'Ada™ output',
            replayChallenge: 'Replay challenge',
            details: 'Details',
            startDate: 'From date',
            dateTo: 'To date',
            type: 'Conversation type',
            topic: 'Topic',
            allConversations: 'All Conversations',
            microTopics: 'Micro-topics',
            amount: 'Amount'
        },
        avaSession: {
            feedback: {
                intro: '今日のAda™のレッスンはいかがでしたか？下記のリンクからフィードバックをお知らせください',
                feedbackForm: 'フィードバックフォーム',
                othersFeedback: `他の人のフィードバックを見る`,
                tryMobile: `スマートフォンで話そう！ぜひお試しください！`
            },
            tips: {
                header: 'Tip of the day!',
                tip0: 'Hi! I’m Ada. My job is to help you become fluent speaking English. Please don’t try to text me. I want you to TALK with me.',
                tip1: 'Ask me: "Can you please repeat more simply?"',
                tip2: 'Tell me: "I don’t understand __X__. What does X mean?"',
                tip3: 'Add keywords to your profile! Tell me your specific occupation and interests.',
                tip4: 'Tell me: "That question is too hard!"',
                tip5: 'Tell me: "Please correct my mistakes."',
                tip6: 'Do you want corrections? Turn them off or on from Ada™ Settings anytime!'
            },
            summary: 'Your transcript is being generated. Please click here to see the summary and vocabulary suggestions: ',
            startLesson: '会話を始める',
            finishLesson: '会話を終了する',
            submitReply: '回答を送信する',
            clickToTalk: 'Click to talk',
            waitingForReply: '返事を待っています...',
            recordingReply: '回答を録音しています...',
            enableMicrophone: 'マイクを有効にしてAda™と話す',
            mobileNote: 'Eメールからリンクを開いた場合は、画面右下のコンパスのアイコンを使ってブラウザに切り替えてください。',
            mobileLoginNote: 'または、ブラウザ（Chrome、Safari）を開き、https://ne.institute のアカウントにログインしてください。',
            userGuide: 'ユーザーガイド',
            userGuideLink: 'https://news.ne.institute/ada-guide-jp',
            settingsModal: {
                title: 'Ada™設定',
                handsFree: 'ハンズフリーモード',
                silenceTimeout: 'Ada™　自動応答時間',
                speechSpeed: 'Ada™の話すスピード',
                enableCorrections: 'Enable Ada™ corrections'
            },
            replyChallenge: 'Replay Challengeのお答えをAda™にお話しください',
            limit: {
                reachedFreeLimit: '今日はここまでにしましょう。また明日！',
                reachedFreeLimit2: '今日はここまでにしましょう。また明日お会いしましょう！もし良ければ、お友達に私、Ada™ のことをおすすめしてくれませんか？ ぜひ、このリンクをお友達にシェアしてください:',
                reachedFreeLimit3: '今日はここまでにしましょう。 NEIのサブスクリプションを購入すると、毎日好きなだけ私とお話できることを知っていましたか？ また、NEIの素晴らしい先生方にもお会いできます。初月を50ドルでお試しいただくには、こちらをクリックしてください。初月のプランにはアメリカ人講師による4回のレッスンとアセスメントが含まれます。',
                toContinueTalking: 'Ada™との会話を続け、サマリーを受け取るにはには無料のアカウントにご登録してください。',
                tryFreeLink: 'TRY NEI  FREE',
                toContinueTalkingConsultation: '引き続きAda™とお話し頂くには、NEIの学習専門家による無料コンサルテーションをご予約ください。',
                toContinueTalkingProfile: '引き続きAda™とお話頂くには、NEIプロフィールを完成させてください。',
                bookConsultation: 'コンサルテーションご予約'
            },
            flexDescription: '自然な自由な会話を楽しみたいときは、Flex Ada™ を選択します。Ada™  は、お客様が話したい内容に沿って会話いたします。トピックの変更をリクエストすると、Ada™ はあなたが登録した別のキーワードについて何か質問をします。さらに、あなたはAdaに直接話したい内容を伝えることもできます。会話は、あらゆる方向へ展開可能です。',
            microTopicsDescription: 'マイクロトピックを選ぶと、マイクロトピックのみに関連した質問から、Ada™が会話を進めていきます。Ada™はトピックの変更を許しません。Ada™ の回答はあなたの話す内容に臨機応変に対応しますが、マイクロトピックの話題から外れることはありません。',
            microTopicsInstruction: 'Select your Micro-Topic, click START CONVERSATION and talk to Ada™!',
            microTopicsLimitedAccess: 'You\'ve been granted temporary access to our premium feature. Enjoy this exclusive access for a limited time.',
            microTopicsLimit: 'You have reached the limit of Micro-Topics conversations for your Try Free account. You can continue using Flex Ada™ daily. Purchase a subscription for unlimited Micro-Topics Ada™!',
            longLoadNote: 'This is taking longer than expected. Click Start Conversation to begin immediately.'
        },
        signUp: {
            title: 'ログインしてください',
            subtitle: 'NEIのアカウントを作成する',
            firstName: 'ファーストネーム',
            email: 'e-mail',
            referralCode: 'プロモーションコード',
            birthDate: '生年月日',
            placeholderBirtDate: '25/11/1997',
            lastName: '苗字',
            phoneNumber: '電話番号',
            yourTimezone: 'あなたのタイムゾーン',
            setPasswordTitle: '今すぐパスワードを設定してください',
            setPasswordNote: 'パスワードは8文字以上で、数字、大文字、小文字を少なくとも1つずつ含むようにしてください',
            password: 'パスワード',
            confirmPassword: 'パスワードを認証する',
            agree: '同意します',
            termsOfUse: '使用期間',
            signUp: 'ログインしてください',
            timezonePlaceholder: '(UTC +10:30) Australian Central Daylight Time',
            areaCode: '国番号',
            createPassword: 'パスワードを作成してください',
            repeatPassword: 'Repeat password',
            emailPlaceholder: 'jonhsmith123@gmail.com'
        },
        book: {
            title: '世界一を体験しよう\nビジネス・プロフェッショナルのための初のパーソナライズドAI講師。\nあなただけのAI、Ada™と今すぐ話しましょう!',
            subTitle: '貴方の目標、そしてそれらを達成するたの最適な方法を話し合いましょう',
            chooseDateText: '10分間のコンサルティングの日にちと時間をお選びください',
            chooseDate: '日にちをお選びください',
            chooseTime: '日時をお選びください',
            chooseTimezone: `お住まいの国のタイムゾーンをお選びください`,
            consultationEnglish: '英語によるコンサルティング',
            consultationJapanese: '日本語によるコンサルティング',
            beforePeopleLooking: '現在',
            peopleLooking: '名の方がコンサルティングを予約しています',
            bookNow: 'ご予約してください',
            notAvailableTeachers: '予約済み'
        },
        about: {
            tryFree: '無料アカウントの作成！',
            title: 'Talk with Ada™ your personalized AI Aide. Ada helps you sound fluent in English discussing your job and interests!',
            firstNote: 'NEIのパーソナライズドAI、Ada™',
            secondNote: 'TESL資格を保有するネイティブ講師によるダイナミックなオンラインレッスン',
            thirdNote: '受講者の仕事、興味、夢中になっている事などに応じて、AIを使って個別に作成される課題',
            videoUrl: 'https://www.youtube.com/embed/JptPShJxcvE?si=zMRcmlxyufUXIF6f'
        },
        confirm: {
            backToHomepage: 'ホームページに戻る',
            bookedConsultation: 'コンサルテーションは予約済みです。',
            bookedNote: 'コンサルテーションが確定次第、ミーティングへのリンクをEメールでお送りします。',
            completeProfile: 'プロフィールを完成させ（数分程度で完了します）、予約したコンサルテーションを確認してください。その後、Ada™と話を続けてください。',
            completeProfileLink: 'プロフィールを完成させてください。',
            requestConsultation: 'コンサルティングをお願いします',
            workingOnConsultation: 'ご希望されましたコンサルティングの予約を準備しています',
            almostReady: 'もう少しです:',
            tryNeiFree: 'NEIを『無料』でお試しください',
            createProfile: 'プロフィールを作成してください',
            sendEmail: 'メールを送信しました:',
            confirmEmail: 'メールを確認して下さい。',
            meetLearning: '英語指導の専門家にお会いください',
            meetNote: 'コンサルテーションをご予約ください。\nカウンセリングの30分前までに必ずプロフィールを完成させてください。',
            talkWithAva: '今すぐAda™と話しましょう！',
            talkWithAvaDescription: 'NEIの画期的なAIは、自信を持ってご自身を表現できるようにサポートします。\nプロフィールの入力を完了させるとすぐにトライアルが始まります。'
        },
        assessment: {
            completed: '前半の評価は完了しました。',
            talkToAvaPrefix: '次は',
            talkToAvaLink: 'リンク',
            talkToAvaSuffix: 'をクリックしてあなただけのAI、Ada™と10分間話をしてください。'
        }
    },
    validationErrors: {
        empty: 'この箇所は必ずご記入ください',
        email: '電子メールは正しい形式である必要があります',
        emailICloud: 'Email addresses @icloud.com are not allowed temporarily. Please use different email address.',
        password: 'パスワードは数字、アルファベットの大文字を含み8桁以上の長さが必要です',
        dontMatchPassword: 'パスワードが一致しません',
        agreeTerms: 'Please read and agree with Terms of Use'
    },
    components: {
        replayChallengTitle: 'Replay Challenge™',
        clickPlayRecording: 'クリックして録音を再生',
        writtenByOn: 'Written by {{teacherName}} on',
        clickRecordAgain: 'もう一度録音する',
        clickStopRecording: 'クリックすると録音を停止します。',
        clickStartRecording: 'または録音済みの音声ファイルを{ここをクリックして}もしくはドラッグしてアップロードしてください。',
        errorPermissionRecording: '録音ができません。お使いのブラウザでマイクを有効にしてください。',
        errorUploadRecording: '録音をアップロードしている最中にエラーが発生しました。もう一度アップロードしてください。',
        noRecordingAvailable: 'まだ録音の提出がありません。',
        noteNotAvailable: 'この1:1 NOTEはまだ準備できていません。準備でき次第メールでお知らせします。',
        browserNotSupportVideo: '申し訳ありませんが、お使いのブラウザは埋め込みビデオに対応していません。',
        errorUploadFileType: 'アップロード中にエラーが発生しました。 ファイルの種類を確認してください。',
        errorUploadFileSize: 'アップロード中にエラーが発生しました。 ファイルのサイズは{{size}}MB以下にしてください。',
        dropFileHere: 'ドラッグしてここにドロップしてください。',
        notes: {
            fetchError: 'エラー： あなたの1:1ノートを読み込むことができませんでした。'
        },
        vcRecordings: {
            noRecordings: '録画がありません。',
            recording: '録画'
        },
        recordings: {
            answer: '回答'
        },
        termsModal: {
            title: '利用規約',
            termsText: 'NEI（以下「当社」）は、本規約に従い、契約者（以下「お客様」）に対してサービスを提供します。当サイトを利用することにより、利用者は以下の条件に同意したものとみなされます。',
            policy: 'キャンセル規約',
            readOur: '当社のお読みください',
            privacy: 'プライバシー',
            privacyText: '当社はユーザー情報の セキュリティと機密性を確保します。NEIスタッフやインストラクターと話し合ったあなたの仕事や会社に関する情報、あるいはいかなる個人情報についても、その秘密を保持します。 あなたのレッスンは、あなたが復習し学習するためだけに録画され、その記録は個人的に使用されるものです。当社はユーザーの財務情報へのアクセス権は保持していません。 当社は所定のサービスを提供するために、ユーザーのコンタクトおよび登録にアクセスしますが、その情報を開示することはありません。\n' + 'ユーザーのパスワードは、標準セキュリティプロトコルで暗号化され保管されます。 NEIのスタッフはユーザーのパスワードへのアクセス権を保持していません。 当社は、二段階のセキュリティを使用しています：ユーザーは、何らかの理由で第三者との共有の必要が生じた場合、そのユーザーのポータルに第二パスワードによってアクセスすることができます。 初期段階ではアクセスする両ポイントが同じパスワードでセットアップされますが、どちらかのパスワードの変更を選択することができます。当社が生の英会話レッスンや学習教材を提供するには、あなたの電子メールアドレスと電話番号が必要となります。当社からスペシャルオファーを含むマーケティングメールを送信することもあります。未成年者が登録する際に親または保護者の許可を得る必要があるため、生年月日をお尋ねします。',
            privacyFooter: '当社のシステムからあなたの情報を削除したい場合は、当社からのメール上にある "unsubscribe"をクリックしてください。あるいは、「unsubscribe」か 「delete my information」というメッセージをsupport@NE.Instituteまで送信してください。',
            propertyRights: '知的財産権',
            propertyRightsText: 'NEI のVirtual Immersion Method™ およびそれを構成する機能（Replay Challenge™を含む）は当社の発明です。当社の商品名およびサービスの名称は、登録商標、または商標です。当社のサイトを使用することによって、ユーザーは当社の知的財産を尊重することに同意したとみなされます。'
        }
    }
}
