import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { AddWordPracticeFields } from './types'
import { FormComponents } from 'app/Components'

export const useAddWordPracticeForm = () => {
    const T = useTranslations()

    const wordText = useField<string>({
        key: AddWordPracticeFields.WordText,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: '',
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })

    return {
        wordText
    }
}
