import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import compass from 'assets/compass.png'
import { colors } from 'lib/styles'
import { mobileCheck } from 'app/Pages/utils'
import { Airoid, Nullable } from 'lib/types'
import Modal from 'react-modal'
import {
    useGetDroidSession,
    useDeleteDroidSession,
    usePromptDroid,
    usePromptAva,
    useLessonSummary,
    useCreateAvaConversation, useGetUserSettings, useGetAvaUserStats, useTranscribeRomaji
} from '../actions'
import { useGetCurrentUser, UserModel } from 'app/Actions'
import { Button, IconContainer, MicButtonContainer, Announcement } from 'app/Components'
import { AvaChatMessage, AvaChatPromptRequest, AvaSettings, GetAvaUserStats } from '../types'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { getDayOfYear } from 'date-fns'
import * as UI from 'app/UI'
import Countdown from 'react-countdown'
import ProgressBar from '@ramonak/react-progress-bar'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import {
    AVA_DAILY_LIMIT,
    CONVERSATION_TYPE, CONVERSATIONS_LIST_URL,
    DEFAULT_AVA_SETTINGS, DEFAULT_LANGUAGE, GPT_ROLE, LANGUAGE_CODE, LANGUAGE_PROMPTS, NO_LIMIT_REFERRALS
} from '../constants'
import { AvaSettingsModal } from './AvaSettingsModal'
import { useGetUserData } from 'app/Pages/TryFree'
import { KEYWORDS_PAGE_LINK, PURCHASE_PRODUCT_LINK, SCHEDULING_LINK, TRY_FREE_LINK } from 'lib/utils/constants'
import { toRomaji } from 'wanakana'

export interface AvaProps {
    type: string,
    appointmentId?: number,
    topic?: string
}

export const AvaSession: React.FunctionComponent<AvaProps> = ({
    type,
    appointmentId,
    topic
}) => {

    Modal.setAppElement('#student-web')
    const theme = useTheme()
    const T = useTranslations()

    const MicComponent = UI.Mic
    const droidModelUrl = 'https://nei-prod-tokyo.s3.ap-northeast-1.amazonaws.com/AvaUpdated.vrm'
    const droidLibraryUrl = 'https://susu-demos.s3.ap-northeast-1.amazonaws.com/airoid_ws_lib_demo_20231102/airoidBundle_v20231102_o.js'
    const feedbackFormLink = 'https://forms.gle/7F3pXfNF8kumWzax8'
    const canvasWidth = 500
    const canvasHeight = 300
    const isReplayChallenge = type === CONVERSATION_TYPE.REPLAY_CHALLENGE
    const adaTipsMessages = [
        T.pages.avaSession.tips.tip0,
        T.pages.avaSession.tips.tip1,
        T.pages.avaSession.tips.tip6,
        T.pages.avaSession.tips.tip2,
        T.pages.avaSession.tips.tip3,
        T.pages.avaSession.tips.tip4,
        T.pages.avaSession.tips.tip5
    ]

    const avaChatMessages: AvaChatPromptRequest = {
        messages: []
    }
    const [avaSettings, setAvaSettings] = useState<AvaSettings>(DEFAULT_AVA_SETTINGS)
    const [avaLanguage, setAvaLanguage] = useState<string>(DEFAULT_LANGUAGE)
    const [currentUser, setCurrentUser] = useState<UserModel>()
    const [sendButtonBlur, setSendButtonBlur] = useState<number>(0)
    const [currentUserStats, setCurrentUserStats] = useState<GetAvaUserStats>()
    const [hasConsultation, setHasConsultation] = useState<boolean>(false)
    const [turnsLimit, setTurnsLimit] = useState<number>(AVA_DAILY_LIMIT)
    const [reachedTurnsLimit, setReachedTurnsLimit] = useState<boolean>(false)
    const [transcriptSubmitTimout, setTranscriptSubmitTimout] = useState<number>(0)
    const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState<boolean>(false)
    const [isMicrophoneChecked, setIsMicrophoneChecked] = useState<boolean>(false)
    const [isClearMicrophoneMessage, setIsClearMicrophoneMessage] = useState<boolean>(false)
    const [waitingTeacherReply, setWaitingTeacherReply] = useState<boolean>(false)
    const [isLongLoad, setIsLongLoad] = useState<boolean>(false)
    const [modelDownloadProgress, setModelDownloadProgress] = useState<number>(0)
    const [teacherReply, setTeacherReply] = useState<Nullable<string>>(null)
    const [teacherReplyRomaji, setTeacherReplyRomaji] = useState<Nullable<string>>(null)
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [droidInitiated, setDroidInitiated] = useState<boolean>(false)
    const [droidSession, setDroidSession] = useState<string>()
    const [avaConversationId, setAvaConversationId] = useState<Nullable<string>>()
    const [lessonSummary, setLessonSummary] = useState<Nullable<string>>()
    const [lastMessage, setLastMessage] = useState<Nullable<string>>()
    const [airDroid, setAirDroid] = useState<Airoid>()
    const [chatMessages, setChatMessages] = useState<Array<AvaChatMessage>>(avaChatMessages.messages)
    const { fetch: getDroidSession } = useGetDroidSession(response => {
        setDroidSession(response)
    })
    const { fetch: deleteDroidSession } = useDeleteDroidSession(setDroidSession)
    const { fetch: createAvaConversation, fetchState: { isLoading: isCreatingConversation } } = useCreateAvaConversation((response, request) => {
        setAvaConversationId(response.uid)
        avaChatMessages.uid = response.uid
        setAvaLanguage(response.language)
        avaChatMessages.language = response.language

        if (response.messages) {
            const messages = response.messages.messages
            messages.splice(messages.length - 1, 1)
            setChatMessages(messages)
            avaChatMessages.messages = messages
            setLastMessage(response.lastMessage)
        }
        if ([CONVERSATION_TYPE.MICRO_TOPIC, CONVERSATION_TYPE.WORD_PRACTICE, CONVERSATION_TYPE.REPLAY_CHALLENGE, CONVERSATION_TYPE.FLEX].includes(type)
            || CONFIG.IS_PUBLIC_LANDING_PAGE || response.active) {
            switchMicrophone()
        }
    })
    const startLesson = () => {
        if (!CONFIG.IS_PUBLIC_LANDING_PAGE) {
            getUserConsultation()
        }
        createAvaConversation({droidSessionId: droidSession, type, appointmentId, topic})
        setLessonSummary(null)
    }
    const { fetch: promptAva, fetchState: { isLoading: isPromptingAva } } = usePromptAva((response, request) => {
        processAvaResponse(request.messages, response)
    })
    const { fetch: transcribeRomaji, fetchState: { isLoading: isTranscribingRomaji } } = useTranscribeRomaji((response, request) => {
        setTeacherReplyRomaji(response)
    })

    const { fetch: getUserConsultation } = useGetUserData(setHasConsultation)
    const { fetch: getAvaStats } = useGetAvaUserStats(setCurrentUserStats)
    const { fetch: getCurrentUser} = useGetCurrentUser(setCurrentUser)

    const processAvaResponse = (messages: Array<AvaChatMessage>, response: string) => {
        if (avaLanguage === LANGUAGE_CODE.japanese && response && response.trim().length > 0) {
            transcribeRomaji({text: response})
        }
        setChatMessages([...messages, {
            role: GPT_ROLE.ASSISTANT,
            content: response
        }])
        setTeacherReply(response)
        promptDroid({
            sessionId: droidSession,
            text: response,
            speed: avaSettings.speechSpeed,
            language: avaLanguage
        })
    }

    const { fetch: promptAvaSummary, fetchState: { isLoading: isSummarizingLesson } } = useLessonSummary((response, request) => {
        setLessonSummary(response)
        setAvaConversationId(null)
        setChatMessages([])
    })
    const { fetch: promptDroid } = usePromptDroid((result, request) => {
        return
    })
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        interimTranscript
    } = useSpeechRecognition()

    const submitMessage = () => {
        if (!hasStudentReply() || waitingTeacherReply) {
            return
        }
        if (transcriptSubmitTimout) {
            window.clearTimeout(transcriptSubmitTimout)
        }
        setTranscriptSubmitTimout(0)
        setWaitingTeacherReply(true)
        const newMessages = [...chatMessages, {
            role: GPT_ROLE.USER,
            content: transcript
        }]
        setChatMessages(newMessages)
        avaChatMessages.messages = newMessages
        avaChatMessages.uid = avaConversationId
        avaChatMessages.language = avaLanguage
        avaChatMessages.enableCorrections = avaSettings.enableCorrections
        resetTranscript()
        setTeacherReply(null)
        setTeacherReplyRomaji(null)
        promptAva(avaChatMessages)
    }

    const getLessonSummary = () => {
        if (chatMessages.length > 0) {
            SpeechRecognition.stopListening()
            promptAvaSummary({uid: avaConversationId})
        } else {
            setAvaConversationId(null)
        }
    }

    useEffect(() => {
        if (!isTranscribing() && hasStudentReply() && !waitingTeacherReply && avaSettings.handsFree) {
            const timeoutId = window.setTimeout(submitMessage, avaSettings.autoSubmitTimeout * 1000)
            setTranscriptSubmitTimout(timeoutId)
        } else {
            if (transcriptSubmitTimout) {
                window.clearTimeout(transcriptSubmitTimout)
                setTranscriptSubmitTimout(0)
            }
        }
        setSendButtonBlur(((interimTranscript || '').length * 101) % 20)
    }, [interimTranscript])

    const { fetch: getUserSettings, fetchState: { isLoading: isGettingUserSettings } } = useGetUserSettings((response, request) => {
        if (response?.ava) {
            setAvaSettings(response.ava)
        }
    })

    useEffect(() => {
        const script = document.createElement('script')
        script.src = droidLibraryUrl
        script.async = true
        script.addEventListener('load', () => {
            setScriptLoaded(true)
        })
        document.body.appendChild(script)

        getDroidSession()
        getAvaStats()
        getUserSettings()
        getCurrentUser()

        return () => {
            console.log('[Droid unmount]')
        }
    }, [])

    useEffect(() => {
        if (CONFIG.IS_FREE_CLIENT) {
            const limit = AVA_DAILY_LIMIT
            const referral = currentUser?.referral || ''
            setTurnsLimit(limit)
            if (currentUserStats) {
                setReachedTurnsLimit((currentUserStats.todayTurns >= limit)
                    && NO_LIMIT_REFERRALS.indexOf(referral.toLowerCase()) === -1)
            }
        }
    }, [currentUserStats, hasConsultation, currentUser])

    const hasAvaLimit = () => {
        return CONFIG.IS_PUBLIC_LANDING_PAGE || (CONFIG.IS_FREE_CLIENT && NO_LIMIT_REFERRALS.indexOf(currentUser?.referral?.toLowerCase() || '') === -1)
    }

    const getWindowRatio = () => {
        return mobileCheck() ? 2.5 :
            isReplayChallenge ? 3 : 2.5
    }

    const makeEmptyDroidPrompt = () => {
        promptDroid({
            sessionId: droidSession,
            text: ' '
        })
    }

    useEffect(() => {
        if (isMicrophoneEnabled) {
            // TODO: a workaround for the first audio playback is low volume on mobile
            if (mobileCheck()) {
                makeEmptyDroidPrompt()
                setIsClearMicrophoneMessage(true)
                setTimeout(avaGreeting, 200)
            } else {
                avaGreeting()
            }
        }
    }, [isMicrophoneEnabled])

    // Workaround to enable microphone after the first empty message on mobile
    useEffect(() => {
        if (!listening && isClearMicrophoneMessage) {
            setTimeout(turnOnMicrophone, 150)
            setIsClearMicrophoneMessage(false)
        }
    }, [listening, isClearMicrophoneMessage])

    const avaGreeting = () => {
        const languagePrompts = LANGUAGE_PROMPTS[avaLanguage] || LANGUAGE_PROMPTS[DEFAULT_LANGUAGE]
        if (isReplayChallenge) {
            setTimeout(() => {
                setWaitingTeacherReply(true)
                processAvaResponse([], avaSettings.handsFree ? languagePrompts.replyChallengeHandsFreeGreeting : languagePrompts.replyChallengeGreeting)
            }
            , 100)
        }
        if (lastMessage) {
            setTimeout(() => {
                setWaitingTeacherReply(true)
                processAvaResponse(chatMessages, lastMessage)
            }
            , 100)
        }
        const firstGreeting = CONFIG.IS_FREE_CLIENT && !CONFIG.CLIENT_HAS_TAGS
            && (!currentUserStats || (currentUserStats.totalConversations - currentUserStats.totalMTConversations) === 0)
            && type === CONVERSATION_TYPE.FLEX
        const defaultGreeting = !firstGreeting && [CONVERSATION_TYPE.FLEX, CONVERSATION_TYPE.MICRO_TOPIC].includes(type)
        if (!lastMessage && defaultGreeting) {
            setTimeout(() => {
                setWaitingTeacherReply(true)
                avaChatMessages.messages = [{
                    role: GPT_ROLE.USER,
                    content: languagePrompts.hello
                }]
                avaChatMessages.uid = avaConversationId
                avaChatMessages.language = avaLanguage
                avaChatMessages.enableCorrections = avaSettings.enableCorrections
                promptAva(avaChatMessages)
            }
            , 100)
        }
        if (!lastMessage && !defaultGreeting && !isReplayChallenge) {
            if (CONFIG.IS_PUBLIC_LANDING_PAGE || firstGreeting) {
                const numberOfMessages = languagePrompts.publicGreeting.length
                const message = `${languagePrompts.hello} ${languagePrompts.publicGreeting[ getDayOfYear(new Date()) % numberOfMessages ]}`
                setTimeout(() => {
                    setWaitingTeacherReply(true)
                    processAvaResponse([], `${message} ${avaSettings.handsFree ? '' : languagePrompts.clickMicrophone}`)
                }
                , 100)
            }
        }
    }

    useEffect(() => {
        if (!scriptLoaded || !droidSession) {
            return
        }

        window.droid = new window.airoid.Airoid()
        const startLoadTime = Date.now()
        const params = {
            canvasName: 'avatarCanvas',
            modelUrl: droidModelUrl,
            rendererHeight: window.innerHeight/getWindowRatio(),
            rendererWidth: window.innerWidth/getWindowRatio(),
            cameraPos: {
                x: mobileCheck() ? -0.0715 : -0.0715,
                y: 1.4337,
                z: 1.0965
            },
            targetPos: {
                x: mobileCheck() ? -0.025 : 0.0465,
                y: 1.5655,
                z: 0.007
            },
            // backgroundColor: 'transparent', // optional, default transparent
            interactiveCamera: false, // optional, default false
            onModelLoadingProgress: (progressLoaded, progressTotal) => {
                setModelDownloadProgress(Math.round(100 * progressLoaded / progressTotal))
                if (progressLoaded >= progressTotal) {
                    setDroidInitiated(true)
                    if (isReplayChallenge) {
                        startLesson()
                    }
                }
                if (Math.floor((Date.now() - startLoadTime) / 1000) >= 5) {
                    setIsLongLoad(true)
                }
            }
        }
        window.droid.init(params)

        setTimeout(() => {
            const connectParams = {
                sessionId: droidSession,
                onOpen: () => {},
                onClose: () => {},
                onMessage: text => {},
                onAgentEndOfSpeech: teacherStoppedTalking
            }
            window.droid.connect(connectParams)
        }, 500)
    }, [scriptLoaded, droidSession])

    const checkMicrophoneAllowed = () => {
        navigator.permissions.query({
            name: 'microphone'
        }).then(permissionStatus => {
            const enabled = permissionStatus.state === 'granted'
            const prompt = permissionStatus.state === 'prompt'

            permissionStatus.onchange = function() {
                const enabled = this.state === 'granted'
                if (enabled) {
                    setIsMicrophoneEnabled(true)
                }
            }
            if (enabled) {
                setIsMicrophoneEnabled(true)
            }
            if (prompt) {
                setTimeout(checkMicrophoneAllowed, 300)
            }
        })
        setIsMicrophoneChecked(true)
    }

    const turnOnMicrophone = () => {
        if (!listening) {
            SpeechRecognition.startListening({
                continuous: true,
                language: avaLanguage
            })
        }
    }

    const getNumberTurns = (messages: Array<AvaChatMessage>) => {
        let result = messages.length / 2
        if (!CONFIG.IS_PUBLIC_LANDING_PAGE && currentUserStats && currentUserStats.todayTurns) {
            result += currentUserStats.todayTurns
        }

        return result
    }

    useEffect(() => {
        if (hasAvaLimit() && getNumberTurns(chatMessages) >= turnsLimit && !waitingTeacherReply) {
            setReachedTurnsLimit(true)
            getLessonSummary()
        }
        // && droidInitiated
        if (!waitingTeacherReply && (avaSettings.handsFree && !reachedTurnsLimit)) {
            setTimeout(turnOnMicrophone, 100)
        }
    }, [waitingTeacherReply])

    const teacherStoppedTalking = () => {
        SpeechRecognition.abortListening()
        resetTranscript()
        setWaitingTeacherReply(false)
    }

    const switchMicrophone = () => {
        if (listening) {
            SpeechRecognition.stopListening()
        } else {
            turnOnMicrophone()
            setTimeout(() => checkMicrophoneAllowed(), 100)
        }
    }

    const isTranscribing = () => {
        return interimTranscript && interimTranscript.length > 0
    }

    const hasStudentReply = () => {
        return transcript && transcript.length > 0
    }

    const getDailyLimitMessage = () => {
        const messages = [
            T.pages.avaSession.limit.reachedFreeLimit,
            T.pages.avaSession.limit.reachedFreeLimit2,
            T.pages.avaSession.limit.reachedFreeLimit3
        ]

        return messages[getDailyLimitMessageNumber()]
    }

    const getDailyLimitMessageNumber = () => {
        const totalTurns = currentUserStats?.totalTurns || 0
        const messagesAmount = 3

        return Math.floor(totalTurns/AVA_DAILY_LIMIT) % messagesAmount
    }

    const getUserReferralLink = () => {
        return `https://AdaAnyTime.com/${currentUser?.userUid || ''}`
    }

    const countDownRenderer = ({seconds}) => {
        return <ConversationStatus>Submitting automatically in {seconds} sec...</ConversationStatus>
    }

    const customStyles = {
        content: {
            position: 'absolute',
            outline: 'none',
            padding: 0,
            inset: 0,
            overflow: 'unset',
            'z-index': 1001,
            top: '40px'
        }
    }

    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
    const [showUserManualModal, setShowUserManualModal] = useState<boolean>(false)

    return (
        <Wrapper>
            {/* <Announcement /> */}
            {!CONFIG.IS_PUBLIC_LANDING_PAGE && !isReplayChallenge && (<SettingLinksContainer>
                <a href={'#'} onClick={() => setShowSettingsModal(true)}>
                    <UI.SettingsIcon width={40} height={40} fill={colors.linkColor} />&nbsp;
                    <span>{T.pages.avaSession.settingsModal.title}</span>
                </a>
                <a href={T.pages.avaSession.userGuideLink} target={'_blank'}>{T.pages.avaSession.userGuide}</a>
            </SettingLinksContainer>)}
            <AvaWrapper $isMobile={mobileCheck()}>
                <AvaContainer id="avatarCanvas" width={canvasWidth} height={canvasHeight} $isMobile={mobileCheck()} data-engine="three.js r149" />
                {!droidInitiated && (<ProgressBar completed={modelDownloadProgress} isLabelVisible={false} />)}
                {!droidInitiated && isLongLoad && (<AvaLongLoadNote>{T.pages.avaSession.longLoadNote}</AvaLongLoadNote>)}
            </AvaWrapper>
            <hr/>
            {!reachedTurnsLimit && teacherReply && (<p>{teacherReply}</p>)}
            {!reachedTurnsLimit && teacherReplyRomaji && (<p><br/><b>Romaji: </b>{teacherReplyRomaji}</p>)}
            {waitingTeacherReply && !teacherReply && (<ConversationStatus>{T.pages.avaSession.waitingForReply}</ConversationStatus>)}
            <br/>
            {showSettingsModal && (
                <AvaSettingsModal
                    onClose={()=>setShowSettingsModal(false)}
                    onSave={
                        (settings: AvaSettings) => {
                            setAvaSettings(settings)
                            setShowSettingsModal(false)
                        }
                    }
                    initialSettings={avaSettings}
                />
            )}
            {!browserSupportsSpeechRecognition && (<div>
                <b>Your browser does not support speech-to-text feature.</b>
                <br/>
                <span>
                    To talk to Teacher Ava™ use <b>Chrome</b> or <b>Safari</b> browsers.
                    <br/>
                    You can also talk to Ava™ on your mobile phone.
                </span>
            </div>)}
            {browserSupportsSpeechRecognition && !reachedTurnsLimit && (<div>
                {!avaConversationId && (<div>
                    {currentUserStats && (<AdaTips>
                        <AdaTipsHeader>{T.pages.avaSession.tips.header}</AdaTipsHeader>
                        <AdaTipsText>{adaTipsMessages[ (currentUserStats.totalConversations > 0 ? ((currentUserStats.totalConversations - 1) % (adaTipsMessages.length - 1) + 1) : 0) ]}</AdaTipsText>
                    </AdaTips>)}
                    <Button.Button
                        height={56}
                        fontSize={16}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={startLesson}
                        content={T.pages.avaSession.startLesson}
                        isLoading={isCreatingConversation}
                        /* !droidInitiated */
                        disabled={false} />
                </div>)}
                {avaConversationId && (isMicrophoneEnabled || !isMicrophoneChecked) && (<MicContainer>
                    <MicButtonContainer>
                        {!listening && (<MicButton onClick={switchMicrophone} >{T.pages.avaSession.clickToTalk}</MicButton>)}
                        {listening && (<SendButton onClick={submitMessage} $shadowBlur={sendButtonBlur} $isDisabled={waitingTeacherReply} >{waitingTeacherReply ? '' : T.pages.avaSession.submitReply}</SendButton>)}
                        {Boolean(transcriptSubmitTimout) && (<Countdown
                            date={Date.now() + avaSettings.autoSubmitTimeout * 1000}
                            renderer={countDownRenderer}
                        />)}
                    </MicButtonContainer>
                    {/* <ResponseButtonContainer> */}
                    {/*    <p>{!waitingTeacherReply && isTranscribing() ? T.pages.avaSession.recordingReply : ''}</p>*/}
                    {/* </ResponseButtonContainer> */}
                </MicContainer>)}
                {avaConversationId && isMicrophoneChecked && !isMicrophoneEnabled && (<div>
                    <b>{T.pages.avaSession.enableMicrophone}</b>
                    <br/>
                    {mobileCheck() && (<div>
                        <span>{T.pages.avaSession.mobileNote}</span>
                        <br/>
                        <Compass src={`${CONFIG.IMAGE_API}${CONFIG.PATH_NAME}${compass}`} />
                        <br/>
                        <span>{T.pages.avaSession.mobileLoginNote}</span>
                    </div>)}
                </div>)}
            </div>)}
            {reachedTurnsLimit && CONFIG.IS_PUBLIC_LANDING_PAGE && (<ConversationLimit>
                <br/>
                <b>{T.pages.avaSession.limit.toContinueTalking}</b>
                <br/>
                <br/>
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => document.location.href = TRY_FREE_LINK}
                    content={T.pages.avaSession.limit.tryFreeLink}
                    isLoading={false}
                    disabled={false}
                />
            </ConversationLimit>)}
            {reachedTurnsLimit && CONFIG.IS_FREE_CLIENT && (<ConversationLimit>
                <br/>
                <b>{getDailyLimitMessage()} {getDailyLimitMessageNumber() === 1 &&(<a target="_blank" href={getUserReferralLink()}>{getUserReferralLink()}</a>)}</b>
                <br/>
                <br/>
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => document.location.href = PURCHASE_PRODUCT_LINK}
                    content={T.common.purchaseSubscription}
                    isLoading={false}
                    disabled={false}
                />
            </ConversationLimit>)}
            <hr/>
            <br/>
            {avaConversationId && !CONFIG.IS_PUBLIC_LANDING_PAGE && (<Button.Button
                height={56}
                fontSize={16}
                backgroundColor={colors.blue}
                textColor={colors.white}
                onClick={getLessonSummary}
                content={T.pages.avaSession.finishLesson}
                isLoading={isSummarizingLesson}
                disabled={false}
            />)}
            { lessonSummary && !isReplayChallenge && (<LessonSummary>
                <b>{T.pages.avaSession.summary} <a href={CONVERSATIONS_LIST_URL}>{T.pages.avaConversations.listTitle}</a></b>
            </LessonSummary>)}
            {/* <div> */}
            {/*    Messages:*/}
            {/*    <br/>*/}
            {/*    {chatMessages.map((message, index) => {*/}
            {/*        return (*/}
            {/*            <div key={index}>Role: {message.role}<br/>{message.content}</div>*/}
            {/*        )*/}
            {/*    })}*/}
            {/* </div> */}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 500px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const AvaWrapper = styled.span<{ $isMobile?: boolean }>`
  position: relative;
  display: ${props => props.$isMobile ? 'none' : 'block' };
`

const AvaLongLoadNote = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 400px;
  font-weight: bold;
`

const MicrophoneStatus = styled.div<{ $listening?: boolean }>`
  font-weight: bold;
  text-align: center;
  color: ${props => props.$listening ? colors.darkGreen : colors.red };
  margin-top: 10px;
`

const ConversationStatus = styled.div`
    padding-left: 20px;
`

const ResponseButtonContainer = styled.div`
    padding-left: 20px;
    width: 300px;
`

const MicContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
    justify-content: center;
`

const MicButton = styled.div`
	width: 120px;
	height: 65px;
    color: ${colors.white};
	background-color: ${colors.red};
    border-radius: 40px;
    text-align: center;
  display: table-cell;
  vertical-align: middle;
`

const SendButton = styled.div<{ $shadowBlur?: number; $isDisabled: boolean }>`
	width: 120px;
	height: 65px;
    color: ${props => props.$isDisabled ? colors.gray.typography : colors.red};
    border: solid 2px ${props => props.$isDisabled ? colors.gray.border : colors.red};
	background-color: ${props => props.$isDisabled ? colors.gray.light : colors.white};
    border-radius: 40px;
    text-align: center;
  display: table-cell;
  vertical-align: middle;
  box-shadow: 0 0 ${props => `${props.$shadowBlur || 0}px`} 0px red;
`

const AvaContainer = styled.canvas<{ $isMobile?: boolean }>`
    width: 500px; 
    height: 300px; 
    margin-left: ${props => props.$isMobile ? '25%' : 'auto'};
    touch-action: none;
`

const LessonSummary = styled.div`
    padding-top: 20px;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-wrap;
`

const Compass = styled.img`
    z-index: -1;
    width: 60px;
`

const ConversationLimit = styled.div`
    text-align: center;
    line-height: 20px;
`

const AdaTips = styled.div`
   margin-bottom: 25px;
   padding: 15px;
   border-radius: 15px;
   border: solid 2px ${colors.blue};
   background-color: #02b3e4;
   color: white;
`

const AdaTipsHeader = styled.div`
  font-weight: bold;    
  margin-bottom: 10px;
`

const AdaTipsText = styled.div`
    
`

const SettingLinksContainer = styled.div`
  margin: 7px 0;
  display: flex;
  justify-content: space-between;
`