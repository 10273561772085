import { AppointmentDate, HttpMethod, OnSuccessResponse } from 'lib/types'
import { useFetch } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import {
    AvaChatPromptRequest,
    AvaChatSummaryRequest,
    AvaDroidPromptRequest,
    CreateAvaConversationRequest,
    GetAvaConversationsRequest,
    GetAvaConversationsResponse,
    GetAvaUserStats,
    UserSettingsModel,
    AvaConversationFull,
    GetAvaConversationRequest,
    CreateAvaConversationResponse,
    GetAvaMicroTopicsResponse,
    AvaSummaryRequest,
    GetAvaMicroTopicsRequest,
    AvaConversationMicroTopic,
    GetAvaConversationMicroTopicsResponse,
    AvaTranscribeRomajiRequest,
    GetWordPracticeRequest,
    GetWordPracticeResponse,
    PostWordPracticeRequest,
    DeleteWordPracticeRequest,
    GetWordPracticeOptionsResponse,
    GetWordPracticeOptionsRequest,
    PostWordPracticeAnswerRequest
} from './types'

export const useGetDroidSession = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/droid-session`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useDeleteDroidSession = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/droid-session`,
        method: HttpMethod.DELETE
    }, {
        onSuccess
    })

export const usePromptDroid = (onSuccess: OnSuccessResponse<string, AvaDroidPromptRequest>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/droid-prompt${publicSuffix()}`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const usePromptAva = (onSuccess: OnSuccessResponse<string, AvaChatPromptRequest>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/prompt${publicSuffix()}`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useTranscribeRomaji = (onSuccess: OnSuccessResponse<string, AvaTranscribeRomajiRequest>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/transcribe/romaji`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useLessonSummary = (onSuccess: OnSuccessResponse<string, AvaSummaryRequest>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/ava/summary`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useCreateAvaConversation = (onSuccess: OnSuccessResponse<CreateAvaConversationResponse, CreateAvaConversationRequest>) =>
    useFetch<CreateAvaConversationResponse>({
        url: `${CONFIG.USER_API}/ava/conversation${publicSuffix()}`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useGetUserSettings = (onSuccess: OnSuccessResponse<UserSettingsModel>) =>
    useFetch<UserSettingsModel>({
        url: `${CONFIG.USER_API}/user/settings`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const usePostUserSettings = (onSuccess: OnSuccessResponse<string, UserSettingsModel>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/user/settings`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useGetAvaConversations = (onSuccess: OnSuccessResponse<GetAvaConversationsResponse>) =>
    useFetch<GetAvaConversationsResponse, GetAvaConversationsRequest>({
        url: `${CONFIG.USER_API}/ava/conversation`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetAvaMicroTopicConversations = (onSuccess: OnSuccessResponse<GetAvaConversationMicroTopicsResponse>) =>
    useFetch<GetAvaConversationMicroTopicsResponse, GetAvaConversationsRequest>({
        url: `${CONFIG.USER_API}/ava/conversation/micro-topic`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetAvaUserStats = (onSuccess: OnSuccessResponse<GetAvaUserStats>) =>
    useFetch<GetAvaUserStats>({
        url: `${CONFIG.USER_API}/ava/me/stats`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetAvaMicroTopics = (onSuccess: OnSuccessResponse<GetAvaMicroTopicsResponse>) =>
    useFetch<GetAvaMicroTopicsResponse, GetAvaMicroTopicsRequest>({
        url: `${CONFIG.USER_API}/ava/me/micro-topics`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetAvaWordPracticeTopics = (onSuccess: OnSuccessResponse<GetAvaMicroTopicsResponse>) =>
    useFetch<GetAvaMicroTopicsResponse>({
        url: `${CONFIG.USER_API}/ava/me/word-practice`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetUserConsultation = (onSuccess: OnSuccessResponse<AppointmentDate>) =>
    useFetch<AppointmentDate>({
        url: `${CONFIG.SCHEDULER_API}/appointment/me/consultation`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetAvaConversation = (onSuccess: OnSuccessResponse<AvaConversationFull>) =>
    useFetch<AvaConversationFull, GetAvaConversationRequest>({
        url: `${CONFIG.USER_API}/ava/conversation`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetWordPractice = (onSuccess: OnSuccessResponse<GetWordPracticeResponse>) =>
    useFetch<GetWordPracticeResponse, GetWordPracticeRequest>({
        url: `${CONFIG.USER_API}/ava/word-practice`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const usePostWordPractice = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string, PostWordPracticeRequest>({
        url: `${CONFIG.USER_API}/ava/word-practice`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useDeleteWordPractice = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string, DeleteWordPracticeRequest>({
        url: `${CONFIG.USER_API}/ava/word-practice`,
        method: HttpMethod.DELETE
    }, {
        onSuccess
    })

export const useGetWordPracticeOptions = (onSuccess: OnSuccessResponse<GetWordPracticeOptionsResponse>) =>
    useFetch<GetWordPracticeOptionsResponse, GetWordPracticeOptionsRequest>({
        url: `${CONFIG.USER_API}/ava/word-practice/options`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const usePostWordPracticeAnswer = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string, PostWordPracticeAnswerRequest>({
        url: `${CONFIG.USER_API}/ava/word-practice/answer`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const publicSuffix = (): string => {
    return CONFIG.IS_PUBLIC_LANDING_PAGE ? '-public' : ''
}