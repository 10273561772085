import { Dictionary } from 'lib/types'

export const en_US: Dictionary = {
    common: {
        inputPlaceholder: 'Input text',
        tryAgain: 'Try again',
        noResults: 'No results',
        search: 'Search...',
        close: 'Close',
        save: 'Save',
        seconds: 'seconds',
        doNotShow: 'Do not show again',
        newReleaseAnnouncement: 'Hi from your personalized AI Aide!',
        purchaseSubscription: 'Purchase Subscription',
        retry: 'Retry',
        noMatchResult: 'No result found',
        yes: 'Yes',
        no: 'No',
        date: 'Date',
        error: {
            fetchError: 'There was an error during fetching data, please try again'
        },
        seeInAction: 'See it in action!',
        completed: 'Completed'
    },
    announcements: {
        AvaBetaRelease2023: 'Hi from your personalized AI Aide!',
        replyChallengeReminder: {
            initial: {
                title: 'You haven’t talked to Ada™ yet!',
                note: 'Take a few minutes to tell Ada™ your Replay Challenge Answer. It’s a required part of each lesson and important practice to reach your Target Score!'
            },
            longMissing: {
                title: 'Make a habit of talking with Ada™!',
                note: 'It’s been a while since your last conversation with Ada™! It’s important to practice speaking regularly, and telling Ada™ your Replay Challenge Answer is a required part of the NEI method.'
            }
        }
    },
    pages: {
        notFound: 'Not found',
        timeLabel: 'Time',
        RecordingsTitle: 'Recordings',
        noteRemeber: 'Remember to listen to the recording all the way to the end!',
        recordNote: 'Click to start recording',
        uploadButton: 'upload',
        recordButton: 'record',
        noRecordsAvailable: 'No recordings yet',
        avaConsent: {
            introduction: 'By participating in the Ada™ Alpha Test I am agreeing to these conditions:',
            underConstruction: '1. I understand that this software service is still under construction. It has not yet been exhaustively tested for bugs. NEI makes no guarantees about the service’s uptime or performance. NEI is giving me early access, at no charge, in exchange for my feedback. I will not make any claims against NEI or hold NEI liable in any way for my experience with Ada™.',
            intellectualProperty: '2. I agree to respect NEI’s intellectual property rights. I will make no attempt to reverse engineer, decompile, disassemble, or otherwise derive the source code of NEI’s software. I will not copy, reproduce, distribute, or create derivative works based on NEI’s software.',
            participation: '3. I understand that if I choose to stop participating weekly I can be removed from the Alpha Test.',
            agree: 'I agree'
        },
        avaConversations : {
            listTitle: 'Ada™ Conversations',
            singleTitle: 'Ada™ Conversation',
            summary: 'Summary',
            status: 'Status',
            detailsLimit: 'You have reached the limit of Ada™ conversations summary review for Try Free account. Purchase a subscription to have unlimited access to Ada™ conversations summary, transcripts, and vocabulary suggestions.',
            recommendations: 'Suggestions',
            start: 'Created',
            lastMessage: 'Last message',
            turns: 'Turns',
            studentOutput: 'Your output (words)',
            teacherOutput: 'Ada™ output',
            replayChallenge: 'Replay challenge',
            details: 'Details',
            startDate: 'From date',
            dateTo: 'To date',
            type: 'Conversation type',
            topic: 'Topic',
            allConversations: 'All Conversations',
            microTopics: 'Micro-topics',
            amount: 'Amount'
        },
        avaSession: {
            feedback: {
                intro: 'How was your Ada™ lesson today? Let us know your feedback using the form link below',
                feedbackForm: 'Feedback form.',
                othersFeedback: `See others' feedback.`,
                tryMobile: `Let's talk on your smartphone! Give it a try!`
            },
            tips: {
                header: 'Tip of the day!',
                tip0: 'Hi! I’m Ada. My job is to help you become a fluent speaker. Please don’t try to text me. I want you to TALK with me.',
                tip1: 'Ask me: "Can you please repeat more simply?"',
                tip2: 'Tell me: "I don’t understand __X__. What does X mean?"',
                tip3: 'Add keywords to your profile! Tell me your specific occupation and interests.',
                tip4: 'Tell me: "That question is too hard!"',
                tip5: 'Tell me: "Please correct my mistakes."',
                tip6: 'Do you want corrections? Turn them off or on from Ada™ Settings anytime!'
            },
            summary: 'Your transcript is being generated. Please click here to see the summary and vocabulary suggestions: ',
            startLesson: 'Start Conversation',
            finishLesson: 'Finish Conversation',
            submitReply: 'Send',
            clickToTalk: 'Click to talk',
            waitingForReply: 'Waiting for reply...',
            recordingReply: 'Recording your reply...',
            enableMicrophone: 'Enable the microphone to talk to Ada™.',
            mobileNote: 'If you opened the link from an email, use the compass icon in the lower-right corner of your screen to switch to a browser:',
            mobileLoginNote: 'Alternatively, open your browser (Chrome, Safari) and log in to your account at https://ne.institute .',
            userGuide: 'User Guide',
            userGuideLink: 'https://news.ne.institute/ada-guide',
            settingsModal: {
                title: 'Ada™ Settings',
                handsFree: 'Hands-free mode',
                silenceTimeout: 'Ada™ automatic response time',
                speechSpeed: 'Ada™ speech speed',
                enableCorrections: 'Enable Ada™ corrections'
            },
            replyChallenge: 'Tell Ada™ your Replay Challenge Answer',
            limit: {
                reachedFreeLimit: 'Our time is up today. Let\'s talk again tomorrow!',
                reachedFreeLimit2: 'Our time is up today. See you tomorrow! Won\'t you please encourage a friend to learn with me? Just share your personal link with your friends:',
                reachedFreeLimit3: 'Our time is up today. Did you know that when you purchase an NEI subscription, you can talk with me for as long as you want every day? Also, you will get to meet NEI\'s wonderful teachers. To try an NEI Introductory Month for just $50 click here. Your Intro Month includes 4 lessons with American teachers, plus an assessment.',
                toContinueTalking: 'To continue talking to Ada™ and receive summaries of your conversations, just click the button!',
                tryFreeLink: 'TRY NEI FREE',
                toContinueTalkingConsultation: 'To continue talking to Ada™, please book your free consultation with an NEI learning expert.',
                toContinueTalkingProfile: 'To continue talking to Ada™, please complete your NEI profile.',
                bookConsultation: 'Book Consultation'
            },
            flexDescription: 'Choose Flex Ada™ when you want to have a free-flowing conversation. Ada™ follows your chosen direction. If you request to change the topic, Ada™ asks about something related to another of your keywords. Or, you tell Ada what you want to talk about. Your conversation can go in an unlimited number of directions.',
            microTopicsDescription: 'You choose your Micro-Topic and Ada™ asks questions specifically about this micro-topic. Ada™ won’t change topics. Her responses will vary depending on what you say, but she will stay on the micro-topic.',
            microTopicsInstruction: 'Select your Micro-Topic, click START CONVERSATION and talk to Ada™!',
            microTopicsLimitedAccess: 'You\'ve been granted temporary access to our premium feature. Enjoy this exclusive access for a limited time.',
            microTopicsPending: 'It\'s time for you to do a Micro-Topic! Keep up with your Micro-Topics so you can earn your next Progress Speaking Assessment.',
            microTopicsLimit: 'You have reached the limit of Micro-Topics conversations for your Try Free account. You can continue using Flex Ada™ daily. Purchase a subscription for unlimited Micro-Topics Ada™!',
            longLoadNote: 'This is taking longer than expected. Click Start Conversation to begin immediately.'
        },
        signUp: {
            title: 'Sign up',
            subtitle: 'Get started with an account on NEI',
            firstName: 'First name',
            email: 'E-mail',
            referralCode: 'Promo code',
            birthDate: 'Birth date',
            placeholderBirtDate: '25/11/1997',
            lastName: 'Last name',
            phoneNumber: 'Phone number',
            yourTimezone: 'Your timezone',
            setPasswordTitle: 'Now set your password',
            setPasswordNote: 'Make sure your password is at least 8 characters long and contains at least one digit, uppercase letter, and lowercase letter',
            password: 'Password',
            confirmPassword: 'Confirm password',
            agree: 'I agree with',
            termsOfUse: 'Terms of use',
            signUp: 'Sign up',
            timezonePlaceholder: '(UTC +10:30) Australian Central Daylight Time',
            areaCode: 'Area code',
            createPassword: 'Create password',
            repeatPassword: 'Repeat password',
            emailPlaceholder: 'jonhsmith123@gmail.com'
        },
        book: {
            title: 'Book your FREE Consultation \nwith an English Learning Expert.\nThen talk with NEI’s personalizing AI Aide, Ada™',
            subTitle: 'Let’s discuss your goals and the most effective way to achieve them.',
            chooseDateText: 'Choose the date and time for your 10-minute consultation',
            chooseDate: 'Choose a date',
            chooseTime: 'Сhoose a time',
            chooseTimezone: `Choose a timezone`,
            consultationEnglish: 'Consultation in English',
            consultationJapanese: 'Consultation in Japanese',
            beforePeopleLooking: '',
            peopleLooking: 'people are booking a consultation now',
            bookNow: 'Book now',
            notAvailableTeachers: 'All consultants are busy'
        },
        about: {
            tryFree: 'Create your free account!',
            title: 'Talk with Ada™ your personalized AI Aide. Ada helps you sound fluent in English discussing your job and interests!',
            firstNote: 'NEI’s personalizing AI Aide, Ada™',
            secondNote: 'Dynamic online Lessons with a TESL-certified native speaker teacher',
            thirdNote: 'Assignments AI-personalized for your job, your interests and your passions',
            videoUrl: 'https://www.youtube.com/embed/-t6oGYFZVsw?si=9_cA0A_l-HnDdUsr'
        },
        confirm: {
            backToHomepage: 'Back to home page',
            bookedConsultation: 'Consultation has been booked',
            bookedNote: 'You will receive a link to the meeting by e-mail as soon as your consultation is confirmed.',
            completeProfile: 'Take a few minutes to complete your profile and confirm the consultation you booked. Then continue talking to Ada™.',
            completeProfileLink: 'Complete Profile',
            requestConsultation: 'Request Consultation',
            workingOnConsultation: 'We’re working on confirming the Consultation you requested.',
            almostReady: 'You’re almost ready to:',
            tryNeiFree: 'TRY NEI FREE',
            createProfile: 'Create Profile',
            sendEmail: 'We sent email to:',
            confirmEmail: 'Please confirm your email.',
            talkWithAva: 'Talk with Ada™ right now!',
            talkWithAvaDescription: 'NEI’s revolutionary AI Aide is ready to help you express yourself clearly and with confidence.\nYour trial will begin as soon as you complete your profile.',
            meetLearning: 'Meet Learning Expert',
            meetNote: 'Book your consultation.\nPlease complete your profile at least 30 minutes before the consultation to keep the time you request.'
        },
        assessment: {
            assessmentNote: 'The Speaking Assessment is intended for intermediate & advanced learners. It’s important to Ada to understand your level so she can adjust the difficulty of her vocabulary and sentence structures. \nIf you are a beginner, you may skip the assessment at this time. Your default level will be Beginner.',
            skipButton: 'Skip Assessment ',
            takeButton: 'Take Assessment',
            completed: 'The first part of your assessment is complete. Your Learning Success Manager will review the results. Now, do two things:',
            completedAISolo: 'Your assessment is complete.',
            talkToAvaPrefix: 'Talk with Ada™ Your AI Aide for 10 minutes. Choose Ada™ Your AI Aide from the left-side menu.',
            scheduleLink: 'SCHEDULE',
            schedulePrefix: '1. Schedule your first 1:1. Click to your',
            scheduleSuffix: '.'
        },
        purchase: {
            assessmentFeature: 'Initial and Progress Assessments',
            lessonsFeature: 'Live 1:1 with professional North American teachers',
            assignmentsFeature: 'AI-Personalized Assignments',
            lsmFeature: 'Learning Success Manager',
            adaFlexFeature: 'Flex Ada™ - Unlimited!',
            adaMTFeature: 'Micro-Topics Ada™ - Unlimited!',
            adaConversationHistoryFeature: 'Ada™ Conversation History',
            adaVocabularySuggestionsFeature: 'Ada™ Vocabulary Suggestions',
            purchase: 'Purchase',
            getFree: 'Get Free',
            preview: 'preview',
            monthlyOnly: 'monthly only',
            monthly: 'Monthly',
            annual: 'Annual (save up to 42%)',
            free: 'Free',
            month: 'month',
            chargeNote: (price, duration) => `You will be charged $${price} for your ${duration} subscription today.`,
            renewNote: date => `Your subscription will automatically renew on ${date}. You can cancel the automatic payment at any time.`,
            havePromoCode: 'I have a promo code',
            preferMonthly: 'I prefer to pay monthly.',
            preferAnnual: (saving => `I want to save ${saving}% with an annual subscription!`)
        }
    },
    validationErrors: {
        empty: 'This field cannot be empty',
        email: 'Email must be in correct format',
        emailICloud: 'Email addresses @icloud.com are not allowed temporarily. Please use different email address.',
        password: 'Password must include number, capital letter and be at least 8 characters long',
        dontMatchPassword: 'Your passwords don\'t match',
        agreeTerms: 'Please read and agree with Terms of Use'
    },
    components: {
        replayChallengTitle: 'Replay Challenge™',
        clickPlayRecording: 'Click to play recording',
        writtenByOn: 'Written by {{teacherName}} on',
        clickRecordAgain: 'Record again',
        clickStopRecording: 'Click to stop recording',
        clickStartRecording: 'or upload an audio file by {clicking here} or dragging and dropping your file here.',
        errorPermissionRecording: 'Please enable microphone access in your browser.',
        errorUploadRecording: 'An error has occurred while uploading your recording. Please try again.',
        errorUploadFileType: 'Error has occurred while uploading. Please check the file type.',
        errorUploadFileSize: 'Error has occurred while uploading. File size should be less than {{size}} mb.',
        noRecordingAvailable: 'You haven\'t submitted a recording yet',
        noteNotAvailable: 'After you do a 1:1 with a native speaker teacher, you will see a note here.',
        browserNotSupportVideo: 'Sorry, your browser doesn\'t support embedded videos.',
        dropFileHere: 'Drag and drop here.',
        notes: {
            fetchError: 'Error: we couldn\'t load your 1:1 notes'
        },
        vcRecordings: {
            noRecordings: 'No recordings',
            recording: 'Recording'
        },
        recordings: {
            answer: 'Answer'
        },
        termsModal: {
            title: 'Terms Of Use',
            termsText: 'NEI (“we”) provides services to Subscriber (“you”) subject to these terms. By using our site, you agree to these terms',
            policy: 'Cancellation Policy',
            readOur: 'Read our',
            privacy: 'Privacy',
            privacyText: 'We protect the security and confidentiality of your information. Any personal information or information about your work or your company that you discuss with NEI staff or teachers will be kept confidential.  Your lessons are video-recorded for your review and study, and for our machine learning algorithms to match the best learning content for you; these recordings are private. We do not have access to your financial information. We access your contact and subscription information in order to provide services, but will not disclose this information. Your password is stored encrypted with standard security protocols. NEI staff do not have access to your password. We request your email address and telephone number so that we can provide you with live English lessons and learning material. We occasionally send marketing emails with special offers.  We request your birth date so that we can identify subscribers with the same or similar names. We request your phone number so that we can send a reminder SMS if you forget your 1:1. ',
            privacyFooter: 'To request that we remove your information from our system, simply click “unsubscribe” on any mailing, or send a message with “unsubscribe” or “delete my information” to support@NE.Institute. ',
            propertyRights: 'Intellectual Property Rights',
            propertyRightsText: 'The NEI Method and its elements (including Replay Challenge™) are our inventions. Our product and service names are registered or unregistered trademarks. By using our site, you agree to respect our intellectual property rights.'
        }
    }
}
