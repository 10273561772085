import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import compass from 'assets/compass.png'
import { colors } from 'lib/styles'
import { mobileCheck } from 'app/Pages/utils'
import { Airoid, Nullable } from 'lib/types'
import Modal from 'react-modal'
import {
    useGetDroidSession,
    useDeleteDroidSession,
    usePromptDroid,
    usePromptAva,
    useLessonSummary,
    useCreateAvaConversation,
    useGetUserSettings,
    useGetAvaUserStats,
    useTranscribeRomaji,
    useGetWordPractice,
    usePostWordPractice, useDeleteWordPractice, usePostWordPracticeAnswer, useGetWordPracticeOptions
} from '../actions'
import { useGetCurrentUser, UserModel } from 'app/Actions'
import { Button, IconContainer, MicButtonContainer, Announcement } from 'app/Components'
import {
    AvaChatMessage,
    AvaChatPromptRequest,
    AvaSettings,
    GetAvaUserStats,
    WordBankItem,
    WordPracticeOption
} from '../types'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { getDayOfYear } from 'date-fns'
import * as UI from 'app/UI'
import Countdown from 'react-countdown'
import ProgressBar from '@ramonak/react-progress-bar'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import {
    AVA_DAILY_LIMIT,
    CONVERSATION_TYPE,
    CONVERSATIONS_LIST_URL,
    DEFAULT_AVA_SETTINGS,
    DEFAULT_LANGUAGE,
    GPT_ROLE,
    LANGUAGE_CODE,
    LANGUAGE_PROMPTS,
    NO_LIMIT_REFERRALS,
    WORD_PRACTICE_ORIGIN_PARAM
} from '../constants'
import { toRomaji } from 'wanakana'
import { AddWordPracticeModal } from './AddWordPracticeModal'

export interface AvaProps {
    type: string,
    appointmentId?: number,
    topic?: string
}

export const AvaWordPractice: React.FunctionComponent<AvaProps> = ({
    type,
    appointmentId,
    topic
}) => {

    Modal.setAppElement('#student-web')
    const theme = useTheme()
    const T = useTranslations()
    const queryParams = new URLSearchParams(window.location.search)

    const droidModelUrl = 'https://nei-prod-tokyo.s3.ap-northeast-1.amazonaws.com/AvaUpdated.vrm'
    const droidLibraryUrl = 'https://susu-demos.s3.ap-northeast-1.amazonaws.com/airoid_ws_lib_demo_20231102/airoidBundle_v20231102_o.js'
    const canvasWidth = 500
    const canvasHeight = 300
    const isReplayChallenge = type === CONVERSATION_TYPE.REPLAY_CHALLENGE

    const avaChatMessages: AvaChatPromptRequest = {
        messages: []
    }
    const [avaSettings, setAvaSettings] = useState<AvaSettings>(DEFAULT_AVA_SETTINGS)
    const [avaLanguage, setAvaLanguage] = useState<string>(DEFAULT_LANGUAGE)
    const [currentUser, setCurrentUser] = useState<UserModel>()
    const [contentLanguage, setContentLanguage] = useState<Nullable<string>>()
    const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState<boolean>(false)
    const [isMicrophoneChecked, setIsMicrophoneChecked] = useState<boolean>(false)
    const [isClearMicrophoneMessage, setIsClearMicrophoneMessage] = useState<boolean>(false)
    const [waitingTeacherReply, setWaitingTeacherReply] = useState<boolean>(false)
    const [isLongLoad, setIsLongLoad] = useState<boolean>(false)
    const [modelDownloadProgress, setModelDownloadProgress] = useState<number>(0)
    const [teacherReply, setTeacherReply] = useState<Nullable<string>>(null)
    const [teacherReplyRomaji, setTeacherReplyRomaji] = useState<Nullable<string>>(null)
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [droidInitiated, setDroidInitiated] = useState<boolean>(false)
    const [droidSession, setDroidSession] = useState<string>()
    const [avaConversationId, setAvaConversationId] = useState<Nullable<string>>()
    const [activeWordList, setActiveWordList] = useState<Array<WordBankItem>>()
    const [currentWordIndex, setCurrentWordIndex] = useState<number>(0)
    const [currentWordOptionIndex, setCurrentWordOptionIndex] = useState<number>(0)
    const [currentWordItem, setCurrentWordItem] = useState<WordBankItem>()
    const [currentWordItemOptions, setCurrentWordItemOptions] = useState<Array<WordPracticeOption>>()
    const [hasAnswer, setHasAnswer] = useState<boolean>(false)
    const [answerIndex, setAnswerIndex] = useState<number>(-1)
    const { fetch: getDroidSession } = useGetDroidSession(response => {
        setDroidSession(response)
    })

    const { fetch: deleteWordPractice, fetchState: { isLoading: isDeletingWordPractice }} = useDeleteWordPractice(response => {
        console.log(response)
    })
    const { fetch: postWordPracticeAnswer, fetchState: { isLoading: isPostingWordPracticeAnswer }} = usePostWordPracticeAnswer(response => {
        console.log(response)
    })
    const { fetch: getWordPracticeOptions, fetchState: { isLoading: isGettingWordPracticeOptions }} = useGetWordPracticeOptions(response => {
        if (response && response.options) {
            const options = response.options
            shuffle(options)
            setCurrentWordItemOptions(options)
            setHasAnswer(false)
            setAnswerIndex(-1)
        }
    })
    const { fetch: getWordPractice, fetchState: { isLoading: isGettingWordList }} = useGetWordPractice((response, request) => {
        if (response && response.wordBank) {
            const activeWords = response.wordBank
            shuffle(activeWords)
            setActiveWordList(activeWords)
            setCurrentWordIndex(0)
            setCurrentWordItem(activeWords[0])
            getWordPracticeOptions({word: activeWords[0].word, language: contentLanguage})
        }
    })
    const startLesson = () => {
        getWordPractice({activeSet: true, language: contentLanguage, originId: queryParams.get(WORD_PRACTICE_ORIGIN_PARAM)})
    }
    const submitAnswer = index => {
        if (currentWordItem && currentWordItemOptions && !hasAnswer) {
            setHasAnswer(true)
            setAnswerIndex(index)
            postWordPracticeAnswer({uid: currentWordItem.uid, correct: currentWordItemOptions[index].isCorrect})
        }
    }
    const repeatWord = () => {
        if (currentWordItem) {
            getWordPracticeOptions({word: currentWordItem.word, language: contentLanguage})
        }
    }
    const nextWord = () => {
        const nextWordIndex = currentWordIndex + 1
        if (currentWordItem && currentWordItemOptions && !hasAnswer) {
            postWordPracticeAnswer({uid: currentWordItem.uid, correct: false})
        }
        if (activeWordList && nextWordIndex < activeWordList.length) {
            setCurrentWordIndex(nextWordIndex)
            setCurrentWordItem(activeWordList[nextWordIndex])
            getWordPracticeOptions({word: activeWordList[currentWordIndex + 1].word, language: contentLanguage})
        } else {
            getWordPractice({activeSet: true, language: contentLanguage})
        }
    }
    const deleteCurrentWord = () => {
        if (currentWordItem) {
            deleteWordPractice({uid: currentWordItem.uid})
            nextWord()
        }
    }
    const { fetch: transcribeRomaji, fetchState: { isLoading: isTranscribingRomaji } } = useTranscribeRomaji((response, request) => {
        setTeacherReplyRomaji(response)
    })

    const { fetch: getCurrentUser} = useGetCurrentUser(response => {
        setCurrentUser(response)
        setContentLanguage(response.contentLanguage)
    })

    const { fetch: promptDroid } = usePromptDroid((result, request) => {
        return
    })
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        interimTranscript
    } = useSpeechRecognition()

    const { fetch: getUserSettings, fetchState: { isLoading: isGettingUserSettings } } = useGetUserSettings((response, request) => {
        if (response?.ava) {
            setAvaSettings(response.ava)
        }
    })

    useEffect(() => {
        const script = document.createElement('script')
        script.src = droidLibraryUrl
        script.async = true
        script.addEventListener('load', () => {
            setScriptLoaded(true)
        })
        document.body.appendChild(script)

        getDroidSession()
        getUserSettings()
        getCurrentUser()

        return () => {
            console.log('[Droid unmount]')
        }
    }, [])

    useEffect(() => {
        if (!waitingTeacherReply) {
            readOptions()
        }
    }, [currentWordItemOptions, waitingTeacherReply])

    const readOptions = () => {
        if (currentWordItemOptions) {
            if (currentWordOptionIndex < currentWordItemOptions.length) {
                setWaitingTeacherReply(true)
                promptDroid({
                    sessionId: droidSession,
                    language: contentLanguage,
                    text: `${String.fromCharCode(65 + currentWordOptionIndex)}.<break strength="medium"/> ${currentWordItemOptions[currentWordOptionIndex].option}`
                })
                setCurrentWordOptionIndex(currentWordOptionIndex + 1)
            } else {
                setCurrentWordOptionIndex(0)
            }
        }
    }

    const shuffle = array => {
        let currentIndex = array.length

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {

            // Pick a remaining element...
            const randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex--

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]]
        }
    }

    const getWindowRatio = () => {
        return mobileCheck() ? 2.5 :
            isReplayChallenge ? 3 : 2.5
    }

    const makeEmptyDroidPrompt = () => {
        promptDroid({
            sessionId: droidSession,
            text: ' '
        })
    }

    // Workaround to enable microphone after the first empty message on mobile
    useEffect(() => {
        if (!listening && isClearMicrophoneMessage) {
            setTimeout(turnOnMicrophone, 150)
            setIsClearMicrophoneMessage(false)
        }
    }, [listening, isClearMicrophoneMessage])

    useEffect(() => {
        if (!scriptLoaded || !droidSession) {
            return
        }

        window.droid = new window.airoid.Airoid()
        const startLoadTime = Date.now()
        const params = {
            canvasName: 'avatarCanvas',
            modelUrl: droidModelUrl,
            rendererHeight: window.innerHeight/getWindowRatio(),
            rendererWidth: window.innerWidth/getWindowRatio(),
            cameraPos: {
                x: mobileCheck() ? -0.0715 : -0.0715,
                y: 1.4337,
                z: 1.0965
            },
            targetPos: {
                x: mobileCheck() ? -0.025 : 0.0465,
                y: 1.5655,
                z: 0.007
            },
            // backgroundColor: 'transparent', // optional, default transparent
            interactiveCamera: false, // optional, default false
            onModelLoadingProgress: (progressLoaded, progressTotal) => {
                setModelDownloadProgress(Math.round(100 * progressLoaded / progressTotal))
                if (progressLoaded >= progressTotal) {
                    setDroidInitiated(true)
                    if (isReplayChallenge) {
                        startLesson()
                    }
                }
                if (Math.floor((Date.now() - startLoadTime) / 1000) >= 5) {
                    setIsLongLoad(true)
                }
            }
        }
        window.droid.init(params)

        if (mobileCheck()) {
            switchMicrophone()
        }

        setTimeout(() => {
            const connectParams = {
                sessionId: droidSession,
                onOpen: () => {},
                onClose: () => {},
                onMessage: text => {},
                onAgentEndOfSpeech: teacherStoppedTalking
            }
            window.droid.connect(connectParams)
        }, 500)
    }, [scriptLoaded, droidSession])

    const checkMicrophoneAllowed = () => {
        navigator.permissions.query({
            name: 'microphone'
        }).then(permissionStatus => {
            const enabled = permissionStatus.state === 'granted'
            const prompt = permissionStatus.state === 'prompt'

            permissionStatus.onchange = function() {
                const enabled = this.state === 'granted'
                if (enabled) {
                    setIsMicrophoneEnabled(true)
                }
            }
            if (enabled) {
                setIsMicrophoneEnabled(true)
            }
            if (prompt) {
                setTimeout(checkMicrophoneAllowed, 300)
            }
        })
        setIsMicrophoneChecked(true)
    }

    const turnOnMicrophone = () => {
        if (!listening) {
            SpeechRecognition.startListening({
                continuous: true,
                language: avaLanguage
            })
        }
    }

    const teacherStoppedTalking = () => {
        window.setTimeout(() => setWaitingTeacherReply(false), 300)
    }

    const switchMicrophone = () => {
        if (listening) {
            SpeechRecognition.stopListening()
        } else {
            turnOnMicrophone()
            setTimeout(() => checkMicrophoneAllowed(), 100)
        }
    }

    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)

    return (
        <Wrapper>
            <AvaWrapper $isMobile>
                <AvaContainer id="avatarCanvas" width={canvasWidth} height={canvasHeight} $isMobile data-engine="three.js r149" />
            </AvaWrapper>
            {showSettingsModal && (
                <AddWordPracticeModal
                    onClose={()=>setShowSettingsModal(false)}
                    onSave={
                        (word: string) => {
                            setShowSettingsModal(false)
                        }
                    }
                    language={contentLanguage}
                />
            )}
            {!browserSupportsSpeechRecognition && (<div>
                <b>Your browser does not support speech-to-text feature.</b>
                <br/>
                <span>
                    To talk to Teacher Ava™ use <b>Chrome</b> or <b>Safari</b> browsers.
                    <br/>
                    You can also talk to Ava™ on your mobile phone.
                </span>
            </div>)}
            {browserSupportsSpeechRecognition && (<div>
                {!activeWordList && (<div>
                    <Button.Button
                        height={56}
                        fontSize={16}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={startLesson}
                        content={'Start practice'}
                        isLoading={isGettingWordList}
                        disabled={isGettingWordList} />
                </div>)}
                {activeWordList && isMicrophoneChecked && !isMicrophoneEnabled && (<div>
                    <b>{T.pages.avaSession.enableMicrophone}</b>
                    <br/>
                    {mobileCheck() && (<div>
                        <span>{T.pages.avaSession.mobileNote}</span>
                        <br/>
                        <Compass src={`${CONFIG.IMAGE_API}${CONFIG.PATH_NAME}${compass}`} />
                        <br/>
                        <span>{T.pages.avaSession.mobileLoginNote}</span>
                    </div>)}
                </div>)}
                <br/>
                <Button.Button
                    height={56}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => setShowSettingsModal(true)}
                    content={'Add new'}
                    isLoading={false}
                    disabled={false} />
                <br/>
                <br/>
                {activeWordList && currentWordItemOptions && (<div>
                    <SectionHeader>Choose the best usage:</SectionHeader>
                    <b>{currentWordItem?.word} </b> (<a onClick={() => deleteCurrentWord()}>delete</a>)
                    <br/>
                    <br/>
                    {currentWordItemOptions.map((option, index) => {
                        return (<WordPracticeOptionItem
                            onClick={() => submitAnswer(index)}
                            key={index}
                            $isCorrect={option.isCorrect}
                            $highlight={hasAnswer && (answerIndex === index || option.isCorrect)}
                        ><input type={'checkbox'} name={'answer'} value={index} checked={hasAnswer && answerIndex === index} />&nbsp;&nbsp;
                            {String.fromCharCode(65 + index)}. {option.option}
                        </WordPracticeOptionItem>)
                    })}
                    <br/>
                    <br/>
                    <ResponseButtonContainer>
                        <Button.Button
                            height={56}
                            fontSize={16}
                            backgroundColor={colors.blue}
                            textColor={colors.white}
                            onClick={repeatWord}
                            content={'Retry'}
                            isLoading={isGettingWordPracticeOptions}
                            disabled={isGettingWordPracticeOptions} />
                        <Button.Button
                            height={56}
                            fontSize={16}
                            backgroundColor={colors.orange}
                            textColor={colors.white}
                            onClick={nextWord}
                            content={'Next'}
                            isLoading={isGettingWordPracticeOptions}
                            disabled={isGettingWordPracticeOptions} />
                    </ResponseButtonContainer>
                </div>)}
            </div>)}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 500px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const AvaWrapper = styled.span<{ $isMobile?: boolean }>`
  position: relative;
  display: ${props => props.$isMobile ? 'none' : 'block' };
`

const SectionHeader = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    //font-weight: bold;
`

const WordPracticeOptionItem = styled.div<{ $isCorrect?: boolean; $highlight?: boolean }>`
  padding-top: 10px;
  padding-bottom: 15px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${props => props.$highlight ? (props.$isCorrect ? colors.darkGreen : colors.red) : colors.transparent };
  
  &:hover {
    background-color: ${props => props.$highlight ? (props.$isCorrect ? colors.darkGreen : colors.red) : colors.gray.background };
    text-decoration: none;
  }
  
  input {
    visibility: visible;
    cursor: pointer;
  }
`

const ResponseButtonContainer = styled.div`
  display: flex;  
  button {
      width: 50%;
      margin-right: 15px;
    }
`

const AvaContainer = styled.canvas<{ $isMobile?: boolean }>`
    width: 500px; 
    height: 300px; 
    margin-left: ${props => props.$isMobile ? '25%' : 'auto'};
    touch-action: none;
`

const Compass = styled.img`
    z-index: -1;
    width: 60px;
`

const ConversationLimit = styled.div`
    text-align: center;
    line-height: 20px;
`

const AdaTips = styled.div`
   margin-bottom: 25px;
   padding: 15px;
   border-radius: 15px;
   border: solid 2px ${colors.blue};
   background-color: #02b3e4;
   color: white;
`

const AdaTipsHeader = styled.div`
  font-weight: bold;    
  margin-bottom: 10px;
`

const AdaTipsText = styled.div`
    
`

const SettingLinksContainer = styled.div`
  margin: 7px 0;
  display: flex;
  justify-content: space-between;
`