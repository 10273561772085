import { HttpMethod, OnSuccessResponse } from 'lib/types'
import { useFetch } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import {
    CreatePaymentIntentRequest,
    CreatePaymentIntentResponse, PostApplyCouponRequest,
    PostApplyCouponResponse,
    ProductListRequest,
    ProductListResponse
} from './types'

export const useGetStripeKey = (onSuccess: OnSuccessResponse<string>) =>
    useFetch<string>({
        url: `${CONFIG.USER_API}/subscription/stripe-key`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetProductList = (onSuccess: OnSuccessResponse<ProductListResponse>) =>
    useFetch<ProductListResponse>({
        url: `${CONFIG.USER_API}/subscription/product${CONFIG.IS_PUBLIC_LANDING_PAGE ? '-public' : ''}`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const usePostPaymentIntent = (onSuccess: OnSuccessResponse<CreatePaymentIntentResponse, CreatePaymentIntentRequest>) =>
    useFetch<CreatePaymentIntentResponse>({
        url: `${CONFIG.USER_API}/subscription/intent`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const usePostApplyCoupon = (onSuccess: OnSuccessResponse<PostApplyCouponResponse, PostApplyCouponRequest>) =>
    useFetch<PostApplyCouponResponse>({
        url: `${CONFIG.USER_API}/subscription/coupon`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })