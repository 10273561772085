import React from 'react'
import { colors } from 'lib/styles'
import { Button, Dialog, FormAdapters, FormComponents } from 'app/Components'
import styled from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { AddWordPracticeFormShape, useAddWordPracticeForm } from '../form'
import { usePostWordPractice } from '../actions'
import { Nullable } from 'lib/types'

interface AddWordPracticeModalProps {
    onClose: VoidFunction,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSave: Function,
    language?: Nullable<string>
}

export const AddWordPracticeModal: React.FunctionComponent<AddWordPracticeModalProps> = ({ onClose, onSave, language }) => {
    const T = useTranslations()

    const { fetch: postWordPractice, fetchState: { isLoading: isPostingWordPractice } } = usePostWordPractice((response, request) => {
        onSave(request.word)
    })

    const { submit, form } = useForm(useAddWordPracticeForm(), {
        onSuccess(form: AddWordPracticeFormShape) {
            postWordPractice({
                word: form.wordText,
                language
            })
        }
    })

    return (
        <Dialog
            title={T.pages.avaSession.settingsModal.title}
            onClose={onClose}
            dialogStyles={{
                maxWidth: '600px',
                maxHeight: '80%'
            }}
            actions={[
                {
                    backgroundColor: colors.orange,
                    color: colors.white,
                    onClick: () => onClose(),
                    name: T.common.close
                }
            ]}
        >
            <Container>
                <StackSection>
                    <LabelStack>New word</LabelStack>
                    <InlineSection>
                        <FormAdapters.Input
                            {...form.wordText}
                        />
                    </InlineSection>
                </StackSection>
                <Button.Button
                    height={56}
                    fontSize={14}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={submit}
                    content={T.common.save}
                    isLoading={isPostingWordPractice}
                    disabled={false}
                />

            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 350px;
`

const InlineSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`

const StackSection = styled.div`
  margin-bottom: 15px;
`

const LabelInline = styled.div`
    font-weight: 500;
    text-wrap: nowrap;
    margin-left: 10px;
    margin-right: 10px;
`

const LabelStack = styled.div`
    font-weight: 500;
    text-wrap: nowrap;
    margin-bottom: 10px;
`

const Text = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 26px;
`

const Link = styled.a`
    color: ${colors.linkColor};
    text-decoration: none;
    text-transform: lowercase;
`
